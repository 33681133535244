import Link from 'components/ui/Link';
import { useTranslation } from 'hooks/useTranslation';
import Dropdown from './Dropdown';
import type { MenuType } from './Header';

type Props = {
  menuItem: MenuType['menuItem'];
  subMenu: MenuType['subMenuItems'];
  currentLocation?: string;
};

const SubMenu = ({
  subMenu,
  menuItem,
  currentLocation,
}: Props): JSX.Element => {
  const { locale } = useTranslation();

  return (
    <Dropdown
      value={{ name: menuItem.name, link: menuItem.link }}
      currentLocation={currentLocation}
    >
      {subMenu.map((subMenuItem) => {
        return (
          <li key={subMenuItem.name}>
            <Link
              href={`${
                subMenuItem.link.match(/http|https/)
                  ? subMenuItem.link
                  : `/${locale}/${subMenuItem.link.toString()}`
              }`}
            >
              <a className="flex items-center normal-case hover:bg-light-grey text-base p-3 pl-[1.05rem] text-primary no-underline">
                <span className="flex items-center">{subMenuItem.name}</span>
              </a>
            </Link>
          </li>
        );
      })}
    </Dropdown>
  );
};

export default SubMenu;
