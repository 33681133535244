import clsx from 'clsx';
import { forwardRef } from 'react';
import styles from './Input.module.scss';
import Label from './Label';
import type { HTMLProps, Ref } from 'react';

type Props = {
  error?: string;
  groupClasses?: string;
  hasWrapper?: boolean;
  isRequired?: boolean;
  label?: string;
  subLabel?: string;
  noBorder?: boolean;
  useGreyBackground?: boolean;
  className?: string;
  errorClassName?: string;
  ref?: Ref<HTMLInputElement>;
} & HTMLProps<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      error,
      groupClasses,
      hasWrapper,
      isRequired,
      label,
      subLabel,
      noBorder,
      useGreyBackground,
      className,
      errorClassName,
      ...restProps
    },
    ref,
  ) => {
    return (
      <div
        className={clsx(
          styles.controlGroup,
          hasWrapper && styles.hasWrapper,
          groupClasses,
        )}
      >
        {label && (
          <Label className={styles.label} isRequired={isRequired}>
            {label}
          </Label>
        )}
        <input
          ref={ref}
          {...restProps}
          className={clsx(
            styles.input,
            noBorder && styles.noBorder,
            useGreyBackground && styles.useGreyBackground,
            error && styles.hasError,
            className,
          )}
        />
        {error && (
          <p className={clsx(styles.errorMessage, errorClassName)}>{error}</p>
        )}
        {subLabel && <p className={styles.subLabel}>{subLabel}</p>}
      </div>
    );
  },
);

Input.displayName = 'Input';
export default Input;
