import type { IconProps } from 'components/ui/Icon';

const BE = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd" strokeWidth="1pt">
      <path d="M0 0h213.33v479.99H0z" />
      <path d="M213.33 0H426.67v479.99H213.33z" fill="#ffd90c" />
      <path d="M426.67 0h213.33v479.99H426.67z" fill="#f31830" />
    </g>
  </svg>
);

export default BE;
