import { useCallback, useState, useEffect } from 'react';
import type { Dispatch, SetStateAction } from 'react';

export const useArrows = (
  size: number | null,
): [number | null, Dispatch<SetStateAction<number | null>>] => {
  const [currentFocus, setCurrentFocus] = useState<number | null>(0);

  const handleKeyDown = useCallback(
    (e: { keyCode: number; preventDefault: () => void }) => {
      if (size) {
        if (e.keyCode === 40) {
          // Down arrow
          e.preventDefault();
          // eslint-disable-next-line
          // @ts-ignore
          setCurrentFocus(currentFocus === size - 1 ? 0 : currentFocus + 1);
        } else if (e.keyCode === 38) {
          // Up arrow
          e.preventDefault();
          // eslint-disable-next-line
          // @ts-ignore
          setCurrentFocus(currentFocus === 0 ? size - 1 : currentFocus - 1);
        }
      }
    },
    [size, currentFocus, setCurrentFocus],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleKeyDown]);

  return [currentFocus, setCurrentFocus];
};
