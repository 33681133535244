import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Shield = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="24"
    height="29"
    viewBox="0 0 24 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.50 4.40H0.50L0.50 4.39C0.49 4.14 0.56 3.89 0.70 3.67C0.84 3.45 1.03 3.28 1.27 3.18C4.65 1.70 8.30 0.96 11.99 1.00L11.99 1.00L12.00 1.00C15.69 0.96 19.34 1.70 22.72 3.18C22.96 3.28 23.15 3.45 23.29 3.67C23.43 3.89 23.50 4.14 23.49 4.39L23.49 4.39V4.40V13.78C23.49 13.78 23.49 13.78 23.49 13.78C23.49 16.64 22.63 19.43 21.01 21.79C19.39 24.15 17.09 25.96 14.42 26.99C14.42 26.99 14.42 26.99 14.42 26.99L13.14 27.48L13.14 27.48L13.14 27.48C12.40 27.77 11.59 27.77 10.85 27.48L10.85 27.48L9.57 26.99C9.57 26.99 9.57 26.99 9.57 26.99C6.90 25.96 4.60 24.15 2.98 21.79C1.36 19.43 0.50 16.64 0.50 13.78C0.50 13.78 0.50 13.78 0.50 13.78L0.50 4.40Z"
      fill="none"
      fillOpacity="0.4"
      stroke={color}
    />
    <path
      d="M7.64 12.24L5.70 13.97L10.36 19.23L10.37 19.22L10.37 19.23L18.73 10.63L17.24 8.64L10.45 14.30L7.64 12.24Z"
      fill={color}
    />
  </svg>
);

export default Shield;
