import clsx from 'clsx';
import NextImage from 'next/image';
import { createContentfulLoader } from 'utils/cloudimg';
import styles from './Image.module.scss';
import type { ImageProps } from 'next/image';
import type { ContentfulImageConfig } from 'utils/contentful';

type Props = ImageProps &
  ContentfulImageConfig & {
    contentful?: boolean;
  };

const Image = ({
  contentful = false,
  width,
  height,
  fit,
  focus,
  src,
  quality = 75,
  isSvg,
  className,
  ...props
}: Props): JSX.Element | null => {
  if (!src) {
    return null;
  }

  const contentfulAsset =
    contentful ||
    /^(?:http:|https:)?\/\/images\.ctfassets\.net/.test(src as string);

  return (
    <NextImage
      className={clsx(contentful && styles.image, className)}
      loader={
        contentfulAsset
          ? createContentfulLoader({ fit, focus, isSvg, height })
          : undefined
      }
      src={src}
      width={width}
      height={height}
      quality={quality}
      {...props}
    />
  );
};

export default Image;
