import { useEffect, useState } from 'react';

export const useMedia = (query: string): boolean => {
  const [isMatched, setIsMatched] = useState(false);

  const updateMediaTarget = (media: MediaQueryList): void => {
    setIsMatched(media.matches);
  };

  useEffect(() => {
    const media = window.matchMedia(query);
    setIsMatched(media.matches);

    window.addEventListener('resize', () => {
      updateMediaTarget(media);
    });

    return () => {
      window.removeEventListener('resize', () => updateMediaTarget(media));
    };
  }, []);

  return isMatched;
};
