import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const RoundCheck = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M12 0c-6.62 0-12 5.37-12 12s5.37 12 12 12 12-5.37 12-12-5.37-12-12-12zm-1.25 16.51l-4.5-4.31 1.39-1.43 3.07 2.93 6.10-6.21 1.42 1.40-7.5 7.62z"
    />
  </svg>
);

export default RoundCheck;
