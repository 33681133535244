import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const VerifiedAlternate = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_913_20010)">
      <path
        d="M10.79 7.05C10.61 6.59 10.61 6.09 10.79 5.65L10.85 5.51C11.24 4.58 10.79 3.50 9.86 3.11L9.73 3.05C9.28 2.87 8.92 2.51 8.74 2.06L8.69 1.94C8.29 1.01 7.22 0.57 6.28 0.95L6.16 1.00C5.71 1.18 5.21 1.18 4.76 1.00L4.65 0.95C3.73 0.57 2.65 1.02 2.26 1.95L2.21 2.05C2.03 2.50 1.68 2.86 1.22 3.04L1.12 3.09C0.20 3.48 -0.24 4.55 0.14 5.48L0.19 5.59C0.37 6.04 0.37 6.55 0.19 6.99L0.14 7.12C-0.24 8.05 0.19 9.12 1.13 9.50L1.25 9.55C1.70 9.73 2.06 10.09 2.24 10.54L2.30 10.68C2.68 11.62 3.75 12.06 4.68 11.68L4.82 11.62C5.27 11.43 5.77 11.43 6.22 11.62L6.33 11.67C7.26 12.06 8.34 11.60 8.73 10.68L8.78 10.58C8.96 10.13 9.31 9.77 9.77 9.59L9.86 9.55C10.80 9.16 11.24 8.10 10.85 7.16L10.79 7.05ZM4.98 8.70L2.59 6.69L3.33 5.82L4.84 7.11L7.52 3.93L8.38 4.66L4.98 8.70Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_913_20010">
        <rect
          width="11"
          height="11"
          fill="white"
          transform="translate(0 0.81)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default VerifiedAlternate;
