import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Verified = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <circle fill={color} cx="8" cy="8" r="8" />
    <path
      fill="#FFF"
      d="M6.7,10.9c-0.1,0-0.3,0-0.4-0.1l-2-2.2c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l1.8,1.8l4.5-4.5	C11.5,5,11.8,5,12,5.2s0.2,0.5,0,0.7l-4.8,4.8C7,10.9,6.9,10.9,6.7,10.9z"
    />
  </svg>
);

export default Verified;
