import type { IconProps } from 'components/ui/Icon';

const GB = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#006"
      d="M0 0L640 0 640 480 0 480z"
    />
    <path
      fill="#FFF"
      d="M0,133.1L640,453V346L0,27.4V133.1z M640,132L0,451.7V344.6L640,25.5V132z"
    />
    <path fill="#FFF" d="M238.8,0v480h159.5V0H238.8z M0,160v160h640V160H0z" />
    <path
      fill="#C00"
      d="M0,192v96h640v-96H0z M270.7,0v480h95.7V0H270.7z M0,399.2L159,320h71.3L0,435.1V399.2z M0,81.9L159,160 H87.7L0,117V81.9z M406.7,160L640,43.4v35.2L478,160H406.7z M640,401.1L478,320h71.3l90.7,46.1V401.1z"
    />
  </svg>
);

export default GB;
