import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Bone = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.50 53.7C11.20 53.7 7.60 50.5 6.80 46.4C3.00 45.6 0.10 42.1 0.10 38.1C0.10 33.4 3.90 29.6 8.60 29.6C10.50 29.6 12.20 30.2 13.70 31.3L31.20 13.8C30.10 12.3 29.50 10.5 29.50 8.7C29.50 4 33.30 0.19 38.00 0.19C42.10 0.19 45.50 3 46.30 6.9C50.40 7.6 53.60 11.2 53.60 15.6C53.60 20.4 49.70 24.4 44.80 24.4C42.90 24.4 41.20 23.8 39.70 22.8L37.10 25.4C36.50 26 35.50 26 34.90 25.4C34.30 24.8 34.30 23.8 34.90 23.2L38.50 19.6C38.80 19.3 39.20 19.1 39.60 19.1C40.00 19.1 40.40 19.3 40.70 19.6C41.80 20.7 43.20 21.3 44.70 21.3C47.80 21.3 50.30 18.8 50.30 15.7C50.30 12.6 47.80 10.1 44.70 10.1C43.90 10.1 43.20 9.4 43.20 8.6C43.00 5.8 40.70 3.5 37.80 3.5C34.80 3.5 32.40 5.9 32.40 8.9C32.40 10.4 33.10 11.9 34.20 12.9C34.50 13.2 34.70 13.6 34.70 14C34.70 14.4 34.50 14.8 34.20 15.2L15.00 34.6C14.70 34.9 14.30 35.1 13.80 35.1C13.40 35.1 13.00 34.9 12.70 34.6C11.70 33.5 10.20 32.8 8.70 32.8C5.70 32.8 3.30 35.2 3.30 38.2C3.30 41 5.50 43.4 8.40 43.6C9.20 43.6 9.90 44.3 9.90 45.1C9.90 48.2 12.50 50.7 15.50 50.7C18.60 50.7 21.10 48.2 21.10 45.1C21.10 43.6 20.50 42.1 19.40 41.1C19.10 40.8 18.90 40.4 18.90 40C18.90 39.6 19.10 39.2 19.40 38.9L29.10 29.2C29.70 28.6 30.70 28.6 31.30 29.2C31.90 29.8 31.90 30.8 31.30 31.4L22.60 40.1C23.70 41.6 24.20 43.3 24.20 45.2C24.30 49.8 20.40 53.7 15.50 53.7Z"
      fill={color}
    />
  </svg>
);

export default Bone;
