import { Direction } from 'types/Utilities';
import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Phone = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
    <path
      transform={getRotation(Direction.RIGHT, 11, 11)}
      d="M10.11 7.22C9.44 7.22 8.78 7.11 8.15 6.90C7.84 6.80 7.47 6.89 7.28 7.09L6.04 8.02C4.61 7.25 3.72 6.37 2.97 4.95L3.88 3.74C4.11 3.51 4.20 3.16 4.09 2.84C3.89 2.21 3.78 1.55 3.78 0.88C3.78 0.39 3.38 0 2.90 0H0.88C0.39 0 0 0.39 0 0.88C0 6.46 4.53 11 10.11 11C10.60 11 11 10.60 11 10.11V8.10C11 7.61 10.60 7.22 10.11 7.22Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default Phone;
