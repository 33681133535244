import clsx from 'clsx';
import styles from './Label.module.scss';
import type { ReactNode } from 'react';

type Props = {
  isRequired?: boolean;
  className?: string;
  children: ReactNode;
};

const Label = ({ isRequired, className, children }: Props): JSX.Element => (
  <label className={clsx(styles.label, className)}>
    <>
      {children}
      {isRequired && <sup className={styles.asterisk}>*</sup>}
    </>
  </label>
);

export default Label;
