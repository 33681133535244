import clsx from 'clsx';
import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';
import tailwind from 'utils/breakpoints';
import type { Hit } from 'components/Pages/Search';

const translations: Record<
  string,
  Record<
    'german' | 'english' | 'russian' | 'ukrainian' | 'homeOnly' | 'home',
    string
  >
> = {
  DE: {
    german: 'Deutsch',
    english: 'Englisch',
    russian: 'Russisch',
    ukrainian: 'Ukrainisch',
    homeOnly: 'nur Hausbesuch',
    home: 'Hausbesuch',
  },
  EN: {
    german: 'German',
    english: 'English',
    russian: 'Russian',
    ukrainian: 'Ukrainian',
    homeOnly: 'House Calls Only',
    home: 'House Call',
  },
  RU: {
    german: 'Немецкий',
    english: 'Английский',
    russian: 'Русский',
    ukrainian: 'Украинский',
    homeOnly: 'Только вызов на дом',
    home: 'Вызов на дом',
  },
  UA: {
    german: 'Німецька',
    english: 'Англійська',
    russian: 'Російська',
    ukrainian: 'Украïнська',
    homeOnly: 'Тільки виклик додому',
    home: 'На дому',
  },
};

type Props = {
  hit: Hit;
  locale: string;
  copyToClipboard: (text: string) => void;
  location: string;
};

const Item = ({
  hit,
  locale,
  copyToClipboard,
  location,
}: Props): JSX.Element => {
  const handleCopyToClipboard = (): void => {
    copyToClipboard(
      `${hit['Praxis / Praxisinhaber:innen']}\n${
        {
          DE: hit['Specialty (DE)'] || '',
          EN: hit['Specialty (EN)'] || '',
          RU: hit['Specialty (RU)'] || '',
          UA: hit['Specialty (UA)'] || '',
        }[locale] || ''
      }\n${hit['Str, PLZ, Ort']}\n${hit['Phone (normalized)'] || ''}\n${
        hit['E-Mail-Adresse, unter der Sie bei Anfragen zu erreichen sind'] ||
        ''
      }\n${
        {
          DE: hit['Comment (DE)'] || '',
          EN: hit['Comment (EN)'] || '',
          RU: hit['Comment (RU)'] || '',
          UA: hit['Comment (UA)'] || '',
        }[locale] || ''
      }\n`,
    );
  };

  return (
    <>
      <div className="mb-8">
        <p className="font-bold text-grey-darkest text-sm mb-1">
          <span className="flex items-center justify-between">
            <span className={locale === 'DE' ? '' : 'hidden'}>
              {hit['Specialty (DE)']}
            </span>
            <span className={locale === 'EN' ? '' : 'hidden'}>
              {hit['Specialty (EN)']}
            </span>
            <span className={locale === 'RU' ? '' : 'hidden'}>
              {hit['Specialty (RU)']}
            </span>
            <span className={locale === 'UA' ? '' : 'hidden'}>
              {hit['Specialty (UA)']}
            </span>
            <Icon
              className="inline-flex w-4 md:w-6 ml-3 cursor-pointer md:hidden"
              type="Copy"
              color={tailwind.theme.backgroundColor.navy}
              onClick={handleCopyToClipboard}
            />
          </span>
        </p>
        <p className="font-netto text-navy text-2xl flex justify-between md:justify-start items-center">
          <span>{hit['Praxis / Praxisinhaber:innen']}</span>
          <Icon
            className="w-4 md:w-6 ml-3 cursor-pointer hidden md:inline-flex"
            type="Copy"
            color={tailwind.theme.backgroundColor.navy}
            onClick={handleCopyToClipboard}
          />
        </p>
        <ul className="flex text-xs mt-2 mb-3">
          {hit['Language (EN)'] === 'TRUE' && (
            <li
              className="bg-bluishGrey mr-2 px-2 flex items-center"
              style={{ borderRadius: '4px' }}
            >
              <span className="text-base">🇺🇸</span>
              <span className="ml-2">{translations[locale].english}</span>
            </li>
          )}
          {hit['Language (UA)'] === 'TRUE' && (
            <li
              className="bg-bluishGrey mr-2 px-2 flex items-center"
              style={{ borderRadius: '4px' }}
            >
              <span className="text-base">🇺🇦</span>
              <span className="ml-2">{translations[locale].ukrainian}</span>
            </li>
          )}
          {hit['Language (RU)'] === 'TRUE' && (
            <li
              className="bg-bluishGrey px-2 flex items-center"
              style={{ borderRadius: '4px' }}
            >
              <span className="text-base">🇷🇺</span>
              <span className="ml-2">{translations[locale].russian}</span>
            </li>
          )}
        </ul>
        <p className="text-sm flex items-center mb-2">
          <span className="w-6">
            <Icon
              className="inline-flex mr-3 w-3"
              type="LocationPinAlternate"
              color={tailwind.theme.backgroundColor.navy}
            />
          </span>
          <Link
            href={`https://maps.google.com/?q=${hit['Address (normalized)']}`}
          >
            <a
              className="underline text-siam"
              target="_blank"
              rel="noopener nofollow noreferrer"
            >
              {hit['Str, PLZ, Ort']}
            </a>
          </Link>
          {location !== '52.52523401376979, 13.369348354047876' && (
            <span
              className="bg-bluishGrey px-2 ml-2 text-xs"
              style={{ borderRadius: '4px' }}
            >
              {(hit._rankingInfo.geoDistance / 1000).toFixed(2)}{' '}
              {locale === 'RU' || locale === 'UA' ? 'км' : 'km'}
            </span>
          )}
        </p>

        {hit['Treatment (Home)'] === 'TRUE' && (
          <span
            className={clsx(
              hit['Treatment (Practice)'] === 'FALSE'
                ? 'bg-secondary-medium'
                : 'bg-bluishGrey',
              'text-xs py-1 px-2 mb-2 inline-block',
            )}
            style={{ borderRadius: '4px' }}
          >
            {hit['Treatment (Practice)'] === 'FALSE'
              ? translations[locale].homeOnly
              : translations[locale].home}
          </span>
        )}

        {hit['Phone (normalized)'] && (
          <p className="text-sm flex items-center mb-2">
            <span className="w-6">
              <Icon
                className="inline-flex mr-3 w-3"
                type="Phone"
                color={tailwind.theme.backgroundColor.navy}
              />
            </span>
            <Link href={`tel:${hit['Phone (normalized)']}`}>
              <a className="underline text-siam" target="_blank">
                {hit['Phone (normalized)']}
              </a>
            </Link>
          </p>
        )}
        {hit[
          'E-Mail-Adresse, unter der Sie bei Anfragen zu erreichen sind'
        ] && (
          <p className="text-sm flex items-center">
            <span className="w-6">
              <Icon
                className="inline-flex mr-3 w-4"
                type="Envelope"
                color={tailwind.theme.backgroundColor.navy}
              />
            </span>
            <Link
              href={`mailto:${hit['E-Mail-Adresse, unter der Sie bei Anfragen zu erreichen sind']}`}
            >
              <a
                className="underline text-siam"
                target="_blank"
                rel="noopener nofollow noreferrer"
              >
                {
                  hit[
                    'E-Mail-Adresse, unter der Sie bei Anfragen zu erreichen sind'
                  ]
                }
              </a>
            </Link>
          </p>
        )}

        {hit['Comment (DE)'] && hit['Comment (DE)'] !== ' ' ? (
          <div
            className={clsx(
              'bg-bluishGrey px-4 py-3 mt-6 text-siam text-sm rounded-lg',
              locale !== 'DE' && 'hidden',
            )}
          >
            {hit['Comment (DE)']}
          </div>
        ) : null}
        {hit['Comment (EN)'] && hit['Comment (EN)'] !== ' ' ? (
          <div
            className={clsx(
              'bg-bluishGrey px-4 py-3 mt-6 text-siam text-sm rounded-lg',
              locale !== 'EN' && 'hidden',
            )}
          >
            {hit['Comment (EN)']}
          </div>
        ) : null}
        {hit['Comment (RU)'] && hit['Comment (RU)'] !== ' ' ? (
          <div
            className={clsx(
              'bg-bluishGrey px-4 py-3 mt-6 text-siam text-sm rounded-lg',
              locale !== 'RU' && 'hidden',
            )}
          >
            {hit['Comment (RU)']}
          </div>
        ) : null}
        {hit['Comment (UA)'] && hit['Comment (UA)'] !== ' ' ? (
          <div
            className={clsx(
              'bg-bluishGrey px-4 py-3 mt-6 text-siam text-sm rounded-lg',
              locale !== 'UA' && 'hidden',
            )}
          >
            {hit['Comment (UA)']}
          </div>
        ) : null}
      </div>
      <hr className="my-5" />
    </>
  );
};

export default Item;
