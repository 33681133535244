import type { IconProps } from 'components/ui/Icon';

const DK = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h640.1v480H0z" fill="#c60c30" />
    <path d="M205.71 0h68.57v480h-68.57z" fill="#fff" />
    <path d="M0 205.71h640.1v68.57H0z" fill="#fff" />
  </svg>
);

export default DK;
