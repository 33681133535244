/**
 * For a given MedicalStaff, return a valid name based on the available name parts
 */
export const getStaffFullName = (
  {
    title,
    postTitle,
    firstName,
    lastName,
  }: {
    title?: string;
    postTitle?: string;
    firstName?: string;
    lastName?: string;
  },
  locale = 'en',
): string => {
  const trimmedPostTitle = postTitle?.trim();
  return (
    [title, firstName, lastName].filter(Boolean).join(' ') +
    (trimmedPostTitle && locale !== 'de' ? `, ${trimmedPostTitle}` : '')
  );
};
