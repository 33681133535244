import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Eye = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="56"
    height="46"
    viewBox="0 0 56 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.4 6.99C29.4 7.79 28.8 8.39 28 8.39C27.2 8.39 26.6 7.79 26.6 6.99V1.79C26.6 0.99 27.2 0.39 28 0.39C28.8 0.39 29.4 0.99 29.4 1.79V6.99ZM52.6 26.8C53.2 26.3 54.1 26.4 54.6 27C55.8 28.6 55.8 30.9 54.6 32.5C48.2 40.8 38.5 45.5 28 45.5C17.5 45.5 7.80 40.8 1.40 32.5C0.10 30.9 0.10 28.6 1.40 27C7.80 18.8 17.5 14 28 14C35.70 14 43.3 16.7 49.3 21.8C49.70 22 50 22.3 50.3 22.6C50.9 23.1 50.9 24 50.4 24.6C49.9 25.2 49 25.2 48.4 24.7C48.1 24.4 47.8 24.1 47.5 23.9C45.1 21.9 42.40 20.3 39.5 19.1C42.1 21.9 43.70 25.7 43.70 29.8C43.70 33.9 42.1 37.7 39.5 40.5C44.5 38.5 49 35.2 52.4 30.8C52.9 30.2 52.9 29.4 52.4 28.8C51.9 28.2 52 27.3 52.6 26.8ZM28 42.7C35.1 42.7 40.9 36.9 40.9 29.8C40.9 22.6 35.1 16.8 28 16.8C20.8 16.8 15.1 22.7 15.1 29.8C15.1 36.9 20.9 42.7 28 42.7ZM3.60 30.8C3.10 30.2 3.10 29.4 3.60 28.8C7.00 24.3 11.5 21 16.5 19C13.9 21.8 12.3 25.6 12.3 29.7C12.3 33.8 13.9 37.6 16.5 40.4C11.5 38.5 7.00 35.2 3.60 30.8ZM28 35.8C24.70 35.8 22 33.1 22 29.8C22 26.5 24.70 23.8 28 23.8C31.3 23.8 34 26.5 34 29.8C34 33.1 31.3 35.8 28 35.8ZM31.1 29.8C31.1 31.5 29.7 33 27.9 33C26.2 33 24.7 31.6 24.7 29.8C24.7 28.1 26.1 26.6 27.9 26.6C29.7 26.6 31.1 28 31.1 29.8ZM45.20 14C45.60 14 45.90 13.9 46.20 13.6L48.8 11C49.3 10.5 49.3 9.59 48.8 8.99C48.3 8.49 47.40 8.49 46.8 8.99L44.20 11.6C43.70 12.1 43.70 13 44.20 13.6C44.40 13.8 44.8 14 45.20 14ZM10.8 14C10.4 14 10.1 13.9 9.80 13.6L7.20 11C6.70 10.4 6.70 9.49 7.20 8.99C7.80 8.49 8.70 8.49 9.20 8.99L11.8 11.6C12.3 12.2 12.3 13.1 11.8 13.6C11.5 13.9 11.2 14 10.8 14Z"
      fill={color}
    />
  </svg>
);

export default Eye;
