import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Schedule = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <rect
        x="7.11"
        y="17.50"
        width="3.33"
        height="3.33"
        rx="1.66"
        fill={color}
      />
      <rect
        x="7.11"
        y="26.66"
        width="3.33"
        height="3.33"
        rx="1.66"
        fill={color}
      />
      <path
        d="M36.28 19.16V8.41C36.28 6.75 34.93 5.41 33.28 5.41H29.61"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M7.94 5.41H4.69C3.04 5.41 1.69 6.75 1.69 8.41V32.00C1.69 33.65 3.04 35.00 4.69 35.00H19.61"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path d="M13.36 5.41H24.61" stroke={color} strokeWidth="3" />
      <rect
        x="25.28"
        y="2.33"
        width="3.66"
        height="5.33"
        rx="1.83"
        stroke={color}
        strokeWidth="3"
      />
      <rect
        x="8.61"
        y="2.33"
        width="3.66"
        height="5.33"
        rx="1.83"
        stroke={color}
        strokeWidth="3"
      />
      <line
        x1="2.78"
        y1="12.66"
        x2="25.61"
        y2="12.66"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="32.78"
        y1="12.66"
        x2="34.78"
        y2="12.66"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M38.94 30C38.94 34.23 35.51 37.66 31.28 37.66C27.04 37.66 23.61 34.23 23.61 30C23.61 25.76 27.04 22.33 31.28 22.33C35.51 22.33 38.94 25.76 38.94 30Z"
        stroke={color}
        strokeWidth="3"
      />
      <path
        d="M27.53 30L30.03 32.5L34.61 27.91"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="17.11"
        y="17.50"
        width="3.33"
        height="3.33"
        rx="1.66"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="40" height="40" fill="white" transform="translate(0.44)" />
      </clipPath>
    </defs>
  </svg>
);

export default Schedule;
