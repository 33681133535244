import { Direction } from 'types/Utilities';
import tailwind from 'utils/breakpoints';
import type { IconProps } from 'components/ui/Icon';

const ORIGINAL_WIDTH = 17;
const ORIGINAL_HEIGHT = 16;

const Star = ({
  color = tailwind.theme.backgroundColor.secondary,
  width = ORIGINAL_WIDTH,
  height = ORIGINAL_HEIGHT,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => {
  const w = parseInt(`${width}`);
  const h = parseInt(`${height}`);

  const fullStar = (
    <path
      d="M9.34 13.34C8.86 13.06 8.27 13.06 7.80 13.34L4.42 15.36C4.04 15.58 3.58 15.24 3.68 14.81L4.56 11.04C4.69 10.50 4.51 9.93 4.08 9.56L1.13 7.03C0.80 6.74 0.97 6.19 1.42 6.15L5.32 5.82C5.87 5.78 6.36 5.43 6.57 4.92L8.11 1.34C8.28 0.93 8.85 0.93 9.03 1.34L10.56 4.92C10.78 5.43 11.26 5.78 11.81 5.82L15.72 6.15C16.16 6.19 16.34 6.74 16.00 7.03L13.05 9.56C12.63 9.93 12.44 10.50 12.57 11.04L13.46 14.81C13.56 15.24 13.09 15.58 12.71 15.36L9.34 13.34Z"
      fill={color}
      stroke={color}
      transform={`scale(${w / ORIGINAL_WIDTH} ${h / ORIGINAL_HEIGHT})`}
    />
  );

  return (
    <svg
      viewBox={`0 0 ${w} ${h}`}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={w}
      height={h}
      {...props}
    >
      <g transform={getRotation(Direction.RIGHT, w, h)}>{fullStar}</g>
    </svg>
  );
};

export default Star;
