// eslint-disable-next-line
// @ts-ignore
import { connectHighlight } from 'react-instantsearch-dom';
import type { Hit } from '.';

type Props = {
  highlight: (data: Record<string, unknown>) => Hit[];
  attribute: string;
  hit: Hit;
};

const SearchHighlight = connectHighlight(
  ({ highlight, attribute, hit }: Props): JSX.Element => {
    const parsedHits = highlight({
      highlightProperty: '_highlightResult',
      attribute,
      hit,
    });

    return (
      <span>
        <span className="block text-xl mb-1">
          {parsedHits.map((part, i) =>
            part.isHighlighted ? (
              <span key={i} className="border-b-2 font-bold border-secondary">
                {part.value}
              </span>
            ) : (
              <span key={i}>{part.value}</span>
            ),
          )}
        </span>
        <span className="block text-sm text-silver">{hit.sub_title}</span>
      </span>
    );
  },
);

export default SearchHighlight;
