import type { IconProps } from 'components/ui/Icon';

const Lock = ({
  color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    className={className}
    {...props}
    width="10"
    height="13"
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.39 5.06H8.06L8.06 3.42C8.06 1.63 6.63 0.18 4.88 0.18C3.13 0.18 1.70 1.63 1.70 3.42L1.70 5.06H1.59C0.98 5.06 0.49 5.56 0.50 6.18V11.06C0.50 11.68 0.99 12.18 1.59 12.18L8.39 12.17C8.99 12.17 9.48 11.67 9.48 11.06V6.17C9.48 5.56 8.99 5.06 8.39 5.06L8.39 5.06ZM2.87 5.06L2.87 3.42C2.87 2.29 3.77 1.37 4.88 1.37C5.99 1.37 6.89 2.29 6.89 3.42L6.89 5.06L2.87 5.06Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default Lock;
