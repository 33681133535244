const PreviewBar = (): JSX.Element => (
  <div className="text-center text-primary font-bold py-3 border-b border-black">
    You are in preview mode!{' '}
    {/** use a regular <a /> tag to prevent 500 errors due to route prefetching */}
    {/* eslint-disable-next-line react/forbid-dom-props */}
    <a className="text-blue-700 underline" href="/api/clearPreview">
      Click here to return to normal mode
    </a>
  </div>
);

export default PreviewBar;
