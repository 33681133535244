import clsx from 'clsx';
// eslint-disable-next-line
// @ts-ignore
import { connectHits } from 'react-instantsearch-dom';
import Icon from 'components/ui/Icon';
import tailwind from 'utils/breakpoints';
import { useAnalytics } from 'hooks/useAnalytics';
import { useArrows } from 'hooks/useArrows';
import { useTranslation } from 'hooks/useTranslation';
import SearchHighlight from './SearchHighlight';
import type { Hit } from '.';

type Props = {
  hits: Hit[];
  openIdentifier: string;
};

// -- CHECK RERENDER !!!!!!!!!!!!!!!!!!
const Results = connectHits(({ hits, openIdentifier }: Props): JSX.Element => {
  const { locale, t } = useTranslation();
  const { trackEvent } = useAnalytics();
  const [focus] = useArrows((hits && hits.length) || null);

  return (
    <>
      <p className="text-navy font-bold uppercase p-2">
        {!hits.length ? t('search.noResults') : t('search.results')}
      </p>
      {!!hits.length &&
        hits.map((hit, i) => {
          if (typeof hit.url === 'undefined') {
            return null;
          }

          return (
            <div key={i}>
              <a
                className={clsx(
                  'text-charcoal normal-case font-adelle border-b border-silver flex items-center justify-between p-2 hover:bg-disabled-bg cursor-pointer transition-colors duration-300 ease-in-out',
                  focus === i && 'bg-disabled-bg',
                )}
                title={hit.title as string}
                // eslint-disable-next-line react/forbid-dom-props
                href={
                  hit.type === 'blogPage'
                    ? `/${locale}/blog/${hit.url}`
                    : `/${locale}/${hit.url}`
                }
                key={i}
                onClick={() =>
                  trackEvent({
                    category: 'SearchBar',
                    action: 'Form|Submit',
                    label: `Form|SearchBar|${openIdentifier}|Submit`,
                  })
                }
              >
                <SearchHighlight hit={hit} attribute="title" />
                <Icon
                  type="Angle"
                  color={tailwind.theme.backgroundColor['silver-light']}
                />
              </a>
            </div>
          );
        })}
    </>
  );
});

export default Results;
