// eslint-disable-next-line
// @ts-ignore
import { connectHits } from 'react-instantsearch-dom';
import Item from './Item';
import type { Hit } from 'components/Pages/Search';

const translations: Record<
  string,
  Record<'noResults' | 'noResultsText', string>
> = {
  DE: {
    noResults: 'Keine Ergebnisse',
    noResultsText: `Leider konnten wir keine Ergebnisse finden, die Ihren Suchkriterien entsprechen. Versuchen Sie einen Filter zu entfernen oder zu ändern.`,
  },
  EN: {
    noResults: 'No results',
    noResultsText: `Unfortunately we couldn't find any results that match your selections. Try removing or changing some filters.`,
  },
  RU: {
    noResults: 'Нет результатов',
    noResultsText: `К сожалению, мы не смогли найти результаты, соответствующие вашему выбору. Попробуйте удалить или изменить некоторые фильтры..`,
  },
  UA: {
    noResults: 'Немає результатів',
    noResultsText: `На жаль, ми не змогли знайти жодного результату, який би відповідав вашим виборам. Спробуйте видалити або змінити деякі фільтри.`,
  },
};

type Props = {
  hits: Hit[];
  locale: string;
  copyToClipboard: (text: string) => void;
  location: string;
};

const CustomHits = connectHits(
  ({ hits, locale, copyToClipboard, location }: Props): JSX.Element => {
    return (
      <>
        {hits.map((hit, i) => (
          <Item
            key={i}
            hit={hit}
            locale={locale}
            copyToClipboard={copyToClipboard}
            location={location}
          />
        ))}
        {!hits.length && (
          <div className="leading-6 px-2 md:px-0">
            <p className="font-netto text-navy text-2-188r md:text-1-938r mt-6 md:mt-11 mb-7">
              {translations[locale].noResults}
            </p>
            <p className="mb-16 md:mb-22">
              {translations[locale].noResultsText}
            </p>
          </div>
        )}
      </>
    );
  },
);

export default CustomHits;
