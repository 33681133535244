import type { IconProps } from 'components/ui/Icon';

const Paypal = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg
    {...props}
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.48 15.66L4.76 13.88L4.13 13.87H1.17L3.23 0.73C3.24 0.69 3.26 0.65 3.29 0.62C3.32 0.60 3.36 0.58 3.40 0.58H8.40C10.07 0.58 11.21 0.93 11.81 1.62C12.09 1.94 12.27 2.27 12.36 2.64C12.45 3.03 12.45 3.50 12.36 4.06L12.36 4.10V4.46L12.64 4.62C12.87 4.75 13.06 4.89 13.20 5.06C13.44 5.33 13.60 5.68 13.67 6.10C13.73 6.52 13.71 7.03 13.60 7.61C13.47 8.27 13.27 8.84 12.99 9.31C12.73 9.74 12.40 10.10 12.01 10.38C11.64 10.65 11.20 10.85 10.70 10.98C10.22 11.10 9.66 11.17 9.06 11.17H8.67C8.39 11.17 8.12 11.27 7.91 11.45C7.69 11.63 7.55 11.89 7.51 12.16L7.48 12.32L6.99 15.46L6.97 15.57C6.96 15.61 6.95 15.63 6.93 15.64C6.92 15.65 6.90 15.66 6.88 15.66H4.48Z"
      fill="#28356A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.90 4.14C12.89 4.24 12.87 4.34 12.85 4.44C12.19 7.84 9.93 9.02 7.05 9.02H5.58C5.22 9.02 4.93 9.28 4.87 9.63L3.91 15.77C3.87 16.00 4.05 16.21 4.28 16.21H6.88C7.19 16.21 7.45 15.98 7.50 15.68L7.53 15.54L8.02 12.42L8.05 12.25C8.10 11.94 8.36 11.72 8.67 11.72H9.06C11.58 11.72 13.56 10.69 14.14 7.71C14.38 6.47 14.25 5.43 13.62 4.70C13.42 4.48 13.18 4.29 12.90 4.14Z"
      fill="#298FC2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.21 3.87C12.11 3.84 12.01 3.81 11.90 3.79C11.79 3.76 11.68 3.74 11.57 3.72C11.17 3.66 10.74 3.63 10.27 3.63H6.35C6.25 3.63 6.16 3.65 6.08 3.69C5.90 3.78 5.76 3.95 5.73 4.16L4.90 9.47L4.87 9.63C4.93 9.28 5.22 9.02 5.58 9.02H7.05C9.93 9.02 12.19 7.84 12.85 4.44C12.87 4.34 12.89 4.24 12.90 4.14C12.74 4.05 12.55 3.98 12.36 3.91C12.31 3.90 12.26 3.88 12.21 3.87Z"
      fill="#22284F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.73 4.16C5.76 3.95 5.90 3.78 6.08 3.69C6.16 3.65 6.25 3.63 6.35 3.63H10.27C10.74 3.63 11.17 3.66 11.57 3.72C11.68 3.74 11.79 3.76 11.90 3.79C12.01 3.81 12.11 3.84 12.21 3.87C12.26 3.88 12.31 3.90 12.36 3.91C12.55 3.98 12.74 4.06 12.90 4.14C13.10 2.89 12.90 2.03 12.22 1.25C11.48 0.40 10.13 0.03 8.40 0.03H3.40C3.05 0.03 2.74 0.29 2.69 0.64L0.60 13.92C0.56 14.18 0.77 14.41 1.03 14.41H4.12L5.73 4.16Z"
      fill="#28356A"
    />
  </svg>
);

export default Paypal;
