import { QUNOMEDICAL_BRAND_ID } from 'constants/brand';
import Icon from './Icon';
import Image from './Image';
import type { Brand } from 'types/Brand';

type Props = {
  brand: Brand;
  invertedLogo?: boolean;
};

const Logo = ({ brand, invertedLogo }: Props): JSX.Element => {
  if (brand.sys.id !== QUNOMEDICAL_BRAND_ID) {
    const logo =
      invertedLogo && brand.logoInverted ? brand.logoInverted : brand.logo;

    return (
      <Image
        src={logo?.url || ''}
        alt={brand.name || ''}
        height={logo?.height}
        width={logo?.width}
      />
    );
  }

  return (
    <Icon type="Qunomedical" color={invertedLogo ? '#e1e1e1' : '#585a51'} />
  );
};

export default Logo;
