import clsx from 'clsx';
import styles from './Spinner.module.scss';

type Props = {
  /**
   * Classname(s) added to the respective component
   * @default ''
   */
  classes?: string;
  /**
   * if `true` the spinner will use an animation while rotating
   * @default false
   */
  dash?: boolean;
  /**
   * The diameter of the spinner in pixels
   * @default 16
   */
  size?: number;
  /**
   * The stroke thickness of the spinner in pixels
   * @default 3
   */
  thickness?: number;
  /**
   * The style used for the spinner
   * @default 'primary'
   */
  variant?: string;
  /**
   * Classname(s) added to the respective component's wrapper element
   * @default ''
   */
  wrapperClasses?: string;
};

const Spinner = ({
  classes = '',
  dash = false,
  size = 16,
  thickness = 3,
  variant = 'primary',
  wrapperClasses = '',
}: Props): JSX.Element => {
  const themeConfig = {
    primary: `#ffca58`,
    secondary: `#ffffff`,
    tertiary: `#bfc1c2`,
  };

  return (
    <div
      className={clsx(styles.spinner, wrapperClasses)}
      style={{ height: `${size}px`, width: `${size}px` }}
    >
      <svg
        className={classes}
        viewBox={`${size / 2} ${size / 2} ${size} ${size}`}
      >
        <circle
          className={dash ? 'dash' : ''}
          cx={size}
          cy={size}
          r={(size - thickness) / 2}
          fill="none"
          strokeWidth={thickness}
          stroke={themeConfig[variant as keyof typeof themeConfig]}
        />
      </svg>
    </div>
  );
};

export default Spinner;
