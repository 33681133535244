import type { IconProps } from 'components/ui/Icon';

const Qunosuite = ({
  color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    width="243"
    height="40"
    className={className}
    viewBox="0 0 243 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_225_1599)">
      <path
        d="M19.3076 0.0133743C8.64396 0.0133743 0 8.92412 0 19.915C0 30.9059 8.64396 39.8185 19.3076 39.8185C29.9713 39.8185 38.6152 30.9078 38.6152 19.915C38.6152 8.92221 29.9694 0.0133743 19.3076 0.0133743ZM19.2984 32.4168C12.713 32.4168 7.37285 26.9137 7.37285 20.1232C7.37285 13.3327 12.7112 7.82962 19.2984 7.82962C25.8856 7.82962 31.2239 13.3327 31.2239 20.1232C31.2239 26.9137 25.8856 32.4168 19.2984 32.4168Z"
        fill="white"
      />
      <path
        d="M44.2037 24.7362C44.2037 24.7362 44.2074 25.0399 44.2074 24.7362C44.2074 24.4325 44.2074 12.5362 44.2074 12.5362C44.2056 12.4827 44.2 12.4311 44.2 12.3776C44.2 12.3241 44.2056 12.2726 44.2074 12.2191L44.2334 11.8657C44.478 9.83907 46.1512 8.27085 48.182 8.27085C50.2128 8.27085 51.886 9.83907 52.1306 11.8657L52.1454 11.9975C52.1565 12.1236 52.1639 12.2497 52.1639 12.3776C52.1639 12.5056 52.1565 12.6336 52.1454 12.7577V24.6139C52.1454 24.6139 52.1584 24.7285 52.1454 24.8546V27.3206C52.1454 28.0827 52.2788 28.8448 52.5901 29.5382C53.2405 30.988 54.5746 32.1952 57.2188 32.1952C60.7412 32.1952 61.8011 29.4102 62.0883 27.1907V12.5362C62.0864 12.4827 62.0864 12.2707 62.0883 12.2172L62.1142 11.8638C62.3588 9.83716 64.032 8.26894 66.0628 8.26894C68.0937 8.26894 69.7669 9.83716 70.0133 11.8638H70.0281V11.9956C70.0392 12.1217 70.0467 12.2477 70.0467 12.3757C70.0467 12.5037 70.0392 12.6317 70.0281 12.7558V26.5259C69.9818 28.0464 70.0152 28.3845 69.954 28.9423L69.9077 29.7388C69.3796 35.5551 64.8251 39.8682 57.0705 39.8682C49.316 39.8682 44.7003 36.3154 44.4427 29.7388C44.2426 28.4475 44.2 24.7343 44.2 24.7343L44.2037 24.7362Z"
        fill="white"
      />
      <path
        d="M100.931 22.8031L100.9 22.0391L100.907 19.2426C100.907 19.2426 101.732 6.58421 88.261 6.58421C77.9383 6.58421 75.196 13.2181 75.196 17.0937C75.196 17.0937 75.0848 19.8883 75.107 19.9914V34.0786C75.0959 34.2047 75.0885 34.3307 75.0885 34.4587C75.0885 34.5867 75.0959 34.7147 75.107 34.8388L75.1219 34.9706C75.3664 36.9973 77.0396 38.5655 79.0705 38.5655C81.1013 38.5655 82.7745 36.9973 83.0191 34.9706L83.0302 34.8503L83.045 34.6192C83.0469 34.5657 83.0524 34.5141 83.0524 34.4606C83.0524 34.4071 83.0469 34.3556 83.045 34.3021V19.7928C83.045 17.4949 84.5867 14.6602 88.2592 14.6602C92.1633 14.6602 93.0583 17.661 93.0805 20.0067L93.1065 21.9913C93.1009 22.0505 93.1065 22.0849 93.1065 22.0849L92.986 34.0786C92.9749 34.2047 92.9675 34.3307 92.9675 34.4587C92.9675 34.5867 92.9749 34.7147 92.986 34.8388V34.9706H93.0009C93.2455 36.9973 94.9187 38.5655 96.9495 38.5655C98.9803 38.5655 100.654 36.9973 100.898 34.9706H100.924V34.6173C100.926 34.5638 100.931 34.5122 100.931 34.4587C100.931 34.4052 100.926 34.3537 100.924 34.3002V22.8031H100.931Z"
        fill="white"
      />
      <path
        d="M147.014 29.8266C147.781 29.8266 148.305 30.2316 149.017 31.3318C150.329 33.4368 152.295 34.4396 155.067 34.4396C158.587 34.4396 160.722 32.837 160.722 30.1342C160.722 27.9146 159.373 26.5049 156.508 25.7122L152.2 24.5146C148.098 23.3761 146.039 21.0592 146.039 17.5636C146.039 13.0271 149.578 9.97659 154.822 9.97659C157.481 9.97659 159.86 10.7884 161.471 12.2554C162.725 13.4129 163.418 14.7653 163.418 16.0393C163.418 16.9658 162.857 17.5846 161.995 17.5846C161.246 17.5846 160.759 17.237 160.44 16.4653C159.597 14.3221 157.5 12.9908 154.896 12.9908C151.581 12.9908 149.447 14.6889 149.447 17.3153C149.447 19.2846 150.72 20.6943 153.118 21.3495L157.368 22.5471C161.956 23.8212 163.998 26.0408 163.998 29.7674C163.998 34.4969 160.477 37.4519 154.859 37.4519C151.845 37.4519 149.223 36.6019 147.631 35.1158C146.282 33.8608 145.589 32.6059 145.589 31.4483C145.589 30.4837 146.169 29.8266 147.012 29.8266H147.014Z"
        fill="white"
      />
      <path
        d="M179.292 37.4519C173.168 37.4519 169.609 33.5132 169.609 27.6835V12.2C169.609 11.0615 170.228 10.3472 171.237 10.3472C172.247 10.3472 172.885 11.0615 172.885 12.2V27.6835C172.885 31.8151 175.375 34.4205 179.29 34.4205C183.206 34.4205 185.714 31.7941 185.714 27.6835V12.2C185.714 11.0615 186.333 10.3472 187.343 10.3472C188.353 10.3472 188.99 11.0615 188.99 12.2V27.7618C188.99 33.5533 185.096 37.4538 179.288 37.4538L179.292 37.4519Z"
        fill="white"
      />
      <path
        d="M196.065 35.2323V12.2C196.065 11.0615 196.684 10.3472 197.694 10.3472C198.704 10.3472 199.341 11.0615 199.341 12.2V35.2323C199.341 36.3708 198.704 37.0851 197.694 37.0851C196.684 37.0851 196.065 36.3708 196.065 35.2323Z"
        fill="white"
      />
      <path
        d="M211.75 35.2323V13.5504H205.476C204.446 13.5504 203.79 12.9908 203.79 12.0644C203.79 11.138 204.446 10.5401 205.476 10.5401H221.321C222.332 10.5401 222.988 11.138 222.988 12.0644C222.988 12.9908 222.332 13.5504 221.321 13.5504H215.028V35.2323C215.028 36.3708 214.391 37.0851 213.381 37.0851C212.371 37.0851 211.752 36.3708 211.752 35.2323H211.75Z"
        fill="white"
      />
      <path
        d="M227.456 13.3957C227.456 11.35 228.317 10.5382 230.47 10.5382H241.332C242.344 10.5382 243 11.1169 243 12.0433C243 12.9698 242.344 13.5485 241.332 13.5485H230.713V21.9665H240.676C241.688 21.9665 242.324 22.5261 242.324 23.4525C242.324 24.379 241.686 24.9195 240.676 24.9195H230.713V33.878H241.332C242.344 33.878 243 34.4759 243 35.4023C243 36.3287 242.344 36.8884 241.314 36.8884H230.47C228.317 36.8884 227.456 36.0766 227.456 34.0308V13.3957Z"
        fill="white"
      />
      <path
        d="M121.229 7.11332C112.466 7.11332 105.364 14.4348 105.364 23.4678C105.364 32.5008 112.466 39.8224 121.229 39.8224C129.991 39.8224 137.093 32.5008 137.093 23.4678C137.093 14.4348 129.991 7.11332 121.229 7.11332ZM121.049 32.1895C116.474 32.1895 112.766 28.3673 112.766 23.6512C112.766 18.9351 116.474 15.1129 121.049 15.1129C125.624 15.1129 129.331 18.9351 129.331 23.6512C129.331 28.3673 125.624 32.1895 121.049 32.1895Z"
        fill="white"
      />
      <path
        d="M36.9217 34.6535H32.3338C32.9656 34.0996 33.6197 33.4292 34.2664 32.6174C37.3293 28.7704 38.8913 23.3112 38.6375 19.363C38.3818 15.4166 37.8722 11.01 32.608 5.4515C27.3494 -0.0993301 19.908 -3.4945e-06 19.8931 -3.4945e-06C17.7827 -3.4945e-06 16.0724 1.76304 16.0724 3.93677C16.0724 6.1105 17.6567 7.73602 19.656 7.86209V7.88501C19.656 7.88501 24.9517 7.82006 28.5241 12.3241C31.9243 16.6105 31.4036 22.3218 30.3419 24.7228C28.6668 28.5182 26.1728 30.2774 24.9869 30.9383V22.253C24.9869 20.7765 23.8251 19.5807 22.3946 19.5807C20.9641 19.5807 19.8024 20.7784 19.8024 22.253V36.9323C19.7838 37.0603 19.7709 37.1921 19.7709 37.3258C19.7709 38.8023 20.9326 39.9981 22.3631 39.9981H36.9217C38.354 39.9981 39.5139 38.8004 39.5139 37.3258C39.5139 35.8512 38.3521 34.6535 36.9217 34.6535Z"
        fill="#24C5BC"
      />
      <path
        d="M70.0652 12.5992C70.0707 12.5075 70.0782 12.4177 70.0782 12.3261C70.0782 10.0511 68.2901 8.20782 66.0832 8.20782C63.8764 8.20782 62.0883 10.0511 62.0883 12.3261C62.0883 12.3528 62.092 12.3776 62.092 12.4044V26.3235C62.092 26.3617 62.0864 26.3999 62.0864 26.44C62.0864 28.7016 63.8652 30.5372 66.061 30.5372C68.2567 30.5372 70.0133 28.7226 70.0337 26.4763H70.0652V12.5992Z"
        fill="#24C5BC"
      />
      <path
        d="M136.975 21.4737C136.932 20.9369 136.556 17.2141 134.075 13.8599C131.948 10.9832 130.328 9.74739 127.74 8.51344C123.789 6.63006 119.891 7.14961 119.891 7.14961V7.17827C117.96 7.41512 116.461 9.10941 116.461 11.1647C116.461 13.3843 118.206 15.1836 120.36 15.1836C120.587 15.1836 120.81 15.1588 121.027 15.1206C121.938 15.0747 124.043 15.2123 126.261 17.0364C129.098 19.3687 129.254 22.5873 129.254 22.5873H129.263C129.515 24.5528 131.145 26.0733 133.123 26.0733C135.276 26.0733 137.021 24.2739 137.021 22.0543C137.021 21.8557 137.003 21.6628 136.975 21.4718V21.4737Z"
        fill="#24C5BC"
      />
      <path
        d="M98.4781 10.8896C95.128 6.86309 91.8428 6.77141 89.7564 6.62624C88.526 6.54028 87.6588 6.57084 87.077 6.63388C85.0221 6.75613 83.3934 8.50771 83.3934 10.6566C83.3934 12.8055 85.1463 14.6908 87.3068 14.6908C87.3698 14.6908 87.4328 14.6832 87.4939 14.6812V14.6946C87.4939 14.6946 88.7947 14.5284 90.353 15.0461C91.3221 15.367 92.5006 16.6296 92.7841 17.9036C93.1472 19.5311 93.1102 21.9932 93.1102 21.9932H93.1324C93.264 24.0886 94.9502 25.7485 97.018 25.7485C99.0859 25.7485 100.917 23.9492 100.917 21.7296C100.917 21.7296 101.61 14.6526 98.48 10.8916L98.4781 10.8896Z"
        fill="#24C5BC"
      />
    </g>
    <defs>
      <clipPath id="clip0_225_1599">
        <rect width="243" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Qunosuite;
