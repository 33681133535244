import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Info = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <circle cx="12" cy="12.5" r="11" stroke={color} strokeWidth="2" />
      <rect
        x="13"
        y="17.5"
        width="2"
        height="8"
        rx="1"
        transform="rotate(-180 13 17.5)"
        fill={color}
      />
      <circle
        cx="12"
        cy="7.5"
        r="1"
        transform="rotate(-180 12 7.5)"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Info;
