import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Quote = ({
  color = iconDefault.color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 3.70V16.5C14 16.63 13.94 16.75 13.85 16.85C13.75 16.94 13.63 17 13.5 17H1.5C1.36 17 1.24 16.94 1.14 16.85C1.05 16.75 1 16.63 1 16.5V1.5C1 1.36 1.05 1.24 1.14 1.14C1.24 1.05 1.36 1 1.5 1H11.29L14 3.70Z"
      stroke={color}
      strokeLinejoin="round"
    />
    <rect x="6" y="5" width="6" height="1" fill={color} />
    <rect x="6" y="9" width="6" height="1" fill={color} />
    <rect x="6" y="13" width="6" height="1" fill={color} />
    <rect x="2.5" y="4.5" width="2" height="2" fill={color} />
    <rect x="2.5" y="8.5" width="2" height="2" fill={color} />
    <rect x="2.5" y="12.5" width="2" height="2" fill={color} />
  </svg>
);

export default Quote;
