import type { IconProps } from 'components/ui/Icon';

const CA = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M81.13 0h362.27v512H81.13z"
      fill="#fff"
      transform="translate(74.11) scale(.9375)"
    />
    <path
      d="M-100 0H81.13v512H-100zm543.41 0H624.55v512H443.41zM135.31 247.41l-14.06 4.80 65.45 57.44c4.95 14.76-1.72 19.11-5.97 26.86l71.06-9.02-1.85 71.51 14.71-.423-3.21-70.91 71.13 8.43c-4.40-9.29-8.32-14.23-4.24-29.09l65.41-54.42-11.44-4.14c-9.36-7.22 4.04-34.78 6.06-52.17 0 0-38.19 13.13-40.69 6.26l-9.72-18.68-34.74 38.17c-3.79.91-5.41-.6-6.30-3.80l16.05-79.76-25.42 14.29c-2.12.91-4.25.125-5.65-2.35l-24.45-49.06-25.21 50.95c-1.9 1.82-3.80 2.03-5.38.796l-24.20-13.57 14.53 79.14c-1.15 3.14-3.92 4.02-7.18 2.32l-33.21-37.73c-4.34 6.96-7.29 18.33-13.03 20.88-5.74 2.38-24.98-4.82-37.87-7.63 4.40 15.89 18.17 42.30 9.46 50.95z"
      fill="#bf0a30"
      transform="translate(74.11) scale(.9375)"
    />
  </svg>
);

export default CA;
