import React from 'react';
import type { Brand } from 'types/Brand';

type BrandContextType = {
  setBrand?: (brand: Brand) => void;
  brand: Brand | null;
};

export const BrandContext = React.createContext<BrandContextType>({
  setBrand() {
    return;
  },
  brand: null,
});
