export const capitalize = (text?: string): string => {
  if (!text) {
    return '';
  }

  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

/**
 * Replace special chars
 */
export const replaceSpecialChar = (content?: string): string => {
  const entities = {
    '&#39;': "'",
    '&amp;': '&',
  };

  if (typeof content !== 'undefined' && content !== null) {
    return content.replace(/&#?\w+;/, (match) => {
      return entities[match as keyof typeof entities];
    });
  }

  return content || '';
};

export const convertStringIntoAnchorLink = (str: string): string => {
  return str
    .replace(/[^\w\s]/gi, '')
    .replace(/\s+/g, '-')
    .toLowerCase();
};
