import type { IconProps } from 'components/ui/Icon';

const SE = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd" strokeWidth="1pt">
      <path
        d="M-121.10.302h256V205.1h-256zM-121.10 307.17h256v204.8h-256z"
        fill="#006aa7"
        transform="translate(50.08) scale(.9375)"
      />
      <path
        d="M-121.10 204.98h256v102.4h-256z"
        fill="#fecc00"
        transform="translate(50.08) scale(.9375)"
      />
      <path
        d="M133.84.01h102.4v511.99h-102.4z"
        fill="#fecc00"
        transform="translate(50.08) scale(.9375)"
      />
      <path
        d="M232.99 205.01h460.79v102.4H232.99z"
        fill="#fecc00"
        transform="translate(50.08) scale(.9375)"
      />
      <path
        d="M236.15 307.20h460.79v204.79H236.15zM236.15.302h460.79V205.1H236.15z"
        fill="#006aa7"
        transform="translate(50.08) scale(.9375)"
      />
    </g>
  </svg>
);

export default SE;
