import { useContext, useEffect, useRef, useState } from 'react';
import Search from 'components/Pages/Search/Search';
import config from 'config';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'hooks/useTranslation';
import { buildUrlPath } from 'utils/url';
import { LocaleContext } from 'context/locale';
import { useRouter } from 'next/router';
import { useMedia } from 'hooks/useMedia';
import clsx from 'clsx';
import Link from './Link';
import Icon from './Icon';
import Logo from './Logo';
import Navigation from './Navigation';
import styles from './Header.module.scss';
import Dropdown from './Dropdown';
import type { AlternateSlug } from 'types/AlternateSlugs';
import type { Brand } from 'types/Brand';

type MenuItem = { name: string; link: string };

export type MenuType = {
  menuItem: MenuItem;
  subMenuItems: MenuItem[];
  additionalInfo: string;
};

type Props = {
  brand: Brand | null;
  languageSwitchSlugs: { slugs?: AlternateSlug[] | null; prefix?: string };
  isReduced?: boolean;
  onOpen?: () => void;
  title?: string;
  noMetaTitle?: string;
};

const convertMenuItemsIntoArray = (text?: string): MenuType[] => {
  const groups = text?.split(/#/g).filter(Boolean) || [];
  return groups.reduce((acc: MenuType[], val: string) => {
    const splittedGroups: string[] = val.split(/(.*?)\r?\n/gim).filter(Boolean);
    const [, menuItemName, menuItemLink] =
      splittedGroups[0].match(/\[(.*?)\]\((.*?)\)/) || []; // Get links in markdown style
    const [, menuAdditionalInfo] = val.match(/\*(.*)\*/) || [];
    const subMenuItems = splittedGroups
      .slice(1)
      .map((subMenu) => {
        const [, subMenuItemName, subMenuItemLink] =
          subMenu.match(/\[(.*?)\]\((.+?)\)/) || [];
        return {
          name: subMenuItemName,
          link: subMenuItemLink,
        };
      })
      .filter(Boolean);
    acc.push({
      menuItem: { name: menuItemName, link: menuItemLink },
      subMenuItems,
      additionalInfo: menuAdditionalInfo,
    });

    return acc;
  }, []);
};

const Header = ({
  brand,
  languageSwitchSlugs,
  isReduced = false,
}: Props): JSX.Element => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { locale, t } = useTranslation();
  const { trackEvent } = useAnalytics();
  const { setLocale } = useContext(LocaleContext);
  const menuLinks = convertMenuItemsIntoArray(brand?.menuLinks);
  const {
    query: { locale: routerLocale },
    asPath,
  } = useRouter();

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const isMobile = useMedia('(max-width: 1023px)');

  const isSearchVisible = menuLinks.find(
    (link) => link?.menuItem?.name?.toLowerCase() === t('search').toLowerCase(),
  );

  useEffect(() => {
    // Restore correct locale (e.g. browser back button)
    const pathLocale = window.location.pathname.split('/')[1];
    if (
      pathLocale &&
      config.routeLocales.includes(pathLocale) &&
      locale !== pathLocale
    ) {
      setLocale(pathLocale as string);
    }
  }, [routerLocale]);

  const openSearch = (): void => {
    setIsSearchOpen(true);
    trackEvent({
      category: 'SearchBar',
      action: 'Form|Click',
      label: 'Form|SearchBar|TopNav|Click',
    });
  };

  const getLocalePath = (locale: string, query: string | undefined): string => {
    const localeSlugs =
      languageSwitchSlugs.slugs?.filter((entry) => entry.locale === locale) ||
      [];

    // No alternative slug found
    if (!localeSlugs.length) {
      return buildUrlPath({ paths: [locale] });
    }

    return buildUrlPath({
      paths: [locale, languageSwitchSlugs.prefix || '', localeSlugs[0].slug],
      query,
    });
  };

  return (
    <header className={styles.header} ref={containerRef}>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <div className={styles.logoContainer}>
            <Link href={`/${locale}`}>
              <a className={styles.logo}>{brand && <Logo brand={brand} />}</a>
            </Link>
          </div>
          <div className={styles.extended}>
            <div className="hidden lg:flex items-center">
              {!isReduced && (
                <Navigation className="hidden lg:block" menuLinks={menuLinks} />
              )}
            </div>
            {!!isSearchVisible && !isReduced && (
              <div className={styles.search}>
                <label className={styles.searchLabel} onClick={openSearch}>
                  <Icon
                    className={styles.searchSvg}
                    type="Search"
                    color={!isMobile ? '#585a51' : '#939BA1'}
                  />
                </label>

                <Search
                  open={isSearchOpen}
                  onClose={() => {
                    setIsSearchOpen(false);
                  }}
                  openIdentifier="TopNav"
                />
              </div>
            )}
          </div>
          <div
            className={clsx(
              'absolute left-0 pl-2 lg:flex items-center lg:static',
              styles.languageWrapper,
            )}
          >
            <Dropdown
              value={{
                name: isMobile
                  ? locale.toUpperCase()
                  : t(`langswitch.${locale}`).toUpperCase(),
                link: '',
              }}
              isIconVisible={!isMobile}
            >
              {config.locales.map((l) => {
                const localeMain = l.main;
                const localeMainCountryCode = localeMain.toUpperCase();
                const [query] = asPath.match(/(?=\?).*/g) || []; // Capture the query parameters in the URL

                const localePath = languageSwitchSlugs
                  ? getLocalePath(localeMain, query)
                  : buildUrlPath({ paths: [locale] });

                return (
                  <li key={localeMain}>
                    <a
                      // eslint-disable-next-line react/forbid-dom-props
                      href={localePath}
                      className="flex items-center normal-case hover:bg-light-grey text-base p-3 text-primary no-underline"
                      onClick={() => {
                        setLocale(localeMain);
                      }}
                    >
                      {'en' === localeMain ? (
                        <span className="flex items-center">
                          <Icon
                            className="w-6 h-4 mr-4 shrink-0"
                            type="USUKFlag"
                          />
                          {t(`langswitch.${localeMain}`)}
                        </span>
                      ) : (
                        <>
                          {localeMainCountryCode && (
                            <span className="flex items-center">
                              <Icon
                                type={`Countries.${localeMainCountryCode}`}
                                className="w-6 mr-4 h-4 shrink-0"
                              />{' '}
                              {t(`langswitch.${localeMain}`)}
                            </span>
                          )}
                        </>
                      )}
                    </a>
                  </li>
                );
              })}
            </Dropdown>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          !isReduced ? styles.mobileWrapper : styles.mobileWrapperHidden,
        )}
      >
        <div className={styles.mobileWrapperSlider}>
          {!isReduced && <Navigation menuLinks={menuLinks} />}
        </div>
      </div>
    </header>
  );
};

export default Header;
