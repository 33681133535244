export const generateTransitionClasses = (
  name: string,
): Record<string, string> => {
  return removeEmptyProperties({
    appear: `${name}Appear`,
    appearActive: `${name}AppearActive`,
    appearDone: `${name}AppearDone`,
    enter: `${name}Enter`,
    enterActive: `${name}EnterActive`,
    enterDone: `${name}EnterDone`,
    exit: `${name}Exit`,
    exitActive: `${name}ExitActive`,
    exitDone: `${name}ExitDone`,
  });
};

export const generateScopedTransitionClasses = (
  name: string,
  style: Record<string, string>,
): Record<string, string> => {
  return removeEmptyProperties({
    appear: style[`${name}Appear`],
    appearActive: style[`${name}AppearActive`],
    appearDone: style[`${name}AppearDone`],
    enter: style[`${name}Enter`],
    enterActive: style[`${name}EnterActive`],
    enterDone: style[`${name}EnterDone`],
    exit: style[`${name}Exit`],
    exitActive: style[`${name}ExitActive`],
    exitDone: style[`${name}ExitDone`],
  });
};

const removeEmptyProperties = (
  obj: Record<string, string>,
): Record<string, string> => {
  const newObj: Record<string, string> = {};

  const keysWithTruthyValues = Object.keys(obj).filter((key) => obj[key]);
  for (const key of keysWithTruthyValues) {
    newObj[key] = obj[key];
  }

  return newObj;
};
