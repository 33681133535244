import type { IconProps } from 'components/ui/Icon';

const Visa = ({ getRotation, className, ...props }: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="43"
    height="44"
    viewBox="0 0 43 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <rect x="8" y="3.5" width="27" height="37" fill="#FFCA58" />
      <path
        d="M21.49 25.58C22.50 25.58 23.98 22.39 24.15 17.52H18.84C19.01 22.39 20.49 25.58 21.49 25.58Z"
        fill="#155263"
      />
      <path
        d="M24.36 8.18C25.32 10.05 25.86 12.85 25.96 15.72H30.36C30.00 12.19 27.62 9.29 24.36 8.18Z"
        fill="#155263"
      />
      <path
        d="M12.63 15.72H17.03C17.12 12.85 17.66 10.05 18.63 8.18C15.37 9.29 12.98 12.19 12.63 15.72Z"
        fill="#155263"
      />
      <path
        d="M21.49 7.66C20.49 7.66 19.01 10.85 18.84 15.72H24.15C23.98 10.85 22.50 7.66 21.49 7.66Z"
        fill="#155263"
      />
      <path
        d="M34.04 0.5H8.95C6.98 0.5 5.37 2.10 5.37 4.08V39.91C5.37 41.89 6.98 43.5 8.95 43.5H34.04C36.01 43.5 37.62 41.89 37.62 39.91V4.08C37.62 2.10 36.01 0.5 34.04 0.5ZM27.77 38.12H15.22C14.73 38.12 14.33 37.72 14.33 37.22C14.33 36.73 14.73 36.33 15.22 36.33H27.77C28.26 36.33 28.66 36.73 28.66 37.22C28.66 37.72 28.26 38.12 27.77 38.12ZM31.35 32.75H11.64C11.15 32.75 10.75 32.34 10.75 31.85C10.75 31.35 11.15 30.95 11.64 30.95H31.35C31.84 30.95 32.25 31.35 32.25 31.85C32.25 32.34 31.84 32.75 31.35 32.75ZM21.5 27.37C15.57 27.37 10.75 22.55 10.75 16.62C10.75 10.69 15.57 5.87 21.5 5.87C27.42 5.87 32.25 10.69 32.25 16.62C32.25 22.55 27.42 27.37 21.5 27.37Z"
        fill="#155263"
      />
      <path
        d="M24.36 25.06C27.62 23.95 30.00 21.05 30.36 17.52H25.96C25.86 20.39 25.32 23.19 24.36 25.06Z"
        fill="#155263"
      />
      <path
        d="M12.63 17.52C12.98 21.05 15.37 23.95 18.63 25.06C17.66 23.19 17.12 20.39 17.03 17.52H12.63Z"
        fill="#155263"
      />
    </g>
  </svg>
);

export default Visa;
