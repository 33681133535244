import { Icons } from './Icons';
import type { SVGProps } from 'react';
import type { Direction } from 'types/Utilities';

export type GetRotation = (
  initialDirection: Direction,
  viewboxX: number,
  viewboxY: number,
) => string;

export type IconProps = {
  color?: string;
  secondaryColor?: string;
  getRotation: GetRotation;
  className?: string;
} & SVGProps<SVGSVGElement>;

export type IconComponent = (props: IconProps) => JSX.Element;

export type IconMap = Record<string, IconComponent | undefined>;

export type NestedIconMap = Record<string, IconMap | IconComponent | undefined>;

type Props = {
  type: string;
  direction?: Direction;
} & Omit<IconProps, 'type' | 'getRotation'>;

const Icon = ({ type, direction, ...restProps }: Props): JSX.Element | null => {
  const getIconComponent = (): IconComponent | null => {
    // we only support 1 level of nesting to keep things simple
    if (type.includes('.')) {
      const [folderName, iconName] = type.split('.');
      if (folderName in Icons) {
        const iconMap = Icons[folderName] as IconMap;
        return iconMap[iconName] || null;
      }

      return null;
    }

    const iconMap = Icons as IconMap;
    return iconMap[type] || null;
  };

  const getRotation: GetRotation = (initialDirection, viewboxX, viewboxY) => {
    if (!direction) {
      return 'rotate(0)';
    }

    const angle = (direction - initialDirection) * 90;
    return `rotate(${angle} ${viewboxX / 2} ${viewboxY / 2})`;
  };

  const Component = getIconComponent();
  if (!Component) {
    return null;
  }

  return <Component {...restProps} getRotation={getRotation} />;
};

export default Icon;
