import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Document = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h18v18H0z" />
      <g stroke={color} strokeLinecap="round" strokeLinejoin="round">
        <path d="M15.91 13.21V1.46C15.91.88 15.42.4 14.83.4H3.07C2.48.4 2 .88 2 1.46v15.05c0 .585.48 1.06 1.07 1.06h8.53" />
        <path d="M15.91 13.09h-4.09v4.09m.132.36l3.85-4.05M5.27 4.09h7.36M5.27 7.36h7.36m-7.36 3.27h7.36M5.27 13.91h3.27" />
      </g>
    </g>
  </svg>
);

export default Document;
