import clsx from 'clsx';
import { useTranslation } from 'hooks/useTranslation';
import Link from '../Link';
import Logo from '../Logo';
import ExtendedFooter from './ExtendedFooter';
import styles from './Footer.module.scss';
import type { Brand } from 'types/Brand';
import type { FooterColType, FooterItemType } from 'types/Footer';

type Props = {
  brand: Brand | null;
  fields: FooterColType | FooterItemType;
  isReduced?: boolean;
};

const Footer = ({ brand, fields, isReduced }: Props): JSX.Element => {
  const { t } = useTranslation();
  const isReducedFooter = isReduced || !fields;
  const additionalLinks = brand?.footerLinks?.items;

  return (
    <footer className={styles.footer}>
      <div className={clsx(styles.wrapper, isReducedFooter && styles.reduced)}>
        <div className={styles.logoWrapper}>
          <div className={styles.logo}>
            {brand && <Logo brand={brand} invertedLogo />}
          </div>
        </div>
        {!isReducedFooter && <ExtendedFooter fields={fields} />}
        <div className={styles.linksWrapper}>
          <p className={styles.copyright}>
            {brand?.copyrightText?.replace(
              /{year}/,
              new Date().getFullYear() as unknown as string,
            ) || t('footer.copy', { year: new Date().getFullYear() })}
          </p>
          <ul className={styles.links}>
            {additionalLinks?.map((link, i) => (
              <li key={i}>
                <Link href={link.url}>
                  <a className={styles.navigationItem}>{link.title}</a>
                </Link>
                {i < additionalLinks.length - 1 && (
                  <span className={styles.divider}>|</span>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
