import clsx from 'clsx';
import Icon from 'components/ui/Icon';
import config from 'config';
import Link from './Link';
import styles from './Socials.module.scss';

type Props = {
  color?: string;
  rounded?: boolean;
  bgClass?: string;
  hoverClass?: string;
  className?: string;
};

const Socials = ({
  color = '#585a51',
  rounded = true,
  bgClass = styles.background,
  hoverClass = styles.hover,
  className,
}: Props): JSX.Element => (
  <div className={clsx(styles.container, className)}>
    {config.socials.map((social, i) => (
      <Link
        href={social.link}
        key={i}
        className={clsx(
          styles.item,
          bgClass,
          hoverClass,
          rounded && styles.rounded,
        )}
        target="_blank"
        rel="noopener noreferrer"
        legacyBehavior={false}
      >
        <Icon className={styles.icon} type={social.name} color={color} />
      </Link>
    ))}
  </div>
);

export default Socials;
