import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Search = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.6 13.99C21.6 18.19 18.19 21.59 14 21.59C9.80 21.59 6.40 18.19 6.40 13.99C6.40 9.80 9.80 6.39 14 6.39C18.19 6.39 21.6 9.80 21.6 13.99ZM20.46 22.39C18.67 23.77 16.43 24.59 14 24.59C8.14 24.59 3.40 19.85 3.40 13.99C3.40 8.14 8.14 3.39 14 3.39C19.85 3.39 24.6 8.14 24.6 13.99C24.6 16.33 23.84 18.49 22.56 20.25L23.05 20.73L29.06 26.74C29.64 27.33 29.64 28.28 29.06 28.87C28.47 29.45 27.52 29.45 26.93 28.87L20.92 22.86L20.46 22.39Z"
      fill={color}
    />
  </svg>
);

export default Search;
