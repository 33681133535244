import clsx from 'clsx';
import styles from './Stars.module.scss';

const STAR_FULL = '★';
const STAR_HALF = '½';
const STAR_EMPTY = '☆';

type Props = {
  color?: string;
  rating?: number;
  showEmptyStars?: boolean;
  total?: number;
  starSize?: number;
  className?: string;
};

const Stars = ({
  color = '#ffca58',
  rating = 0,
  total = 5,
  starSize = 16,
  showEmptyStars = true,
  className,
}: Props): JSX.Element => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = fullStars !== rating;
  const emptyStars = total - fullStars - (hasHalfStar ? 1 : 0);

  const starsText =
    STAR_FULL.repeat(fullStars) +
    (hasHalfStar ? STAR_HALF : '') +
    (showEmptyStars && emptyStars ? STAR_EMPTY.repeat(emptyStars) : '');

  return (
    <span
      className={clsx(styles.rating, 'block', className)}
      style={{ fontSize: starSize, color }}
    >
      {starsText}
    </span>
  );
};

export default Stars;
