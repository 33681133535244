// eslint-disable-next-line
// @ts-ignore
import { connectToggleRefinement } from 'react-instantsearch-dom';

type Props = {
  currentRefinement: boolean;
  refine: (value: boolean) => void;
  count: {
    unchecked?: number;
  };
  label: string;
};

const CustomToggleRefinement = connectToggleRefinement(
  ({ currentRefinement, refine, count, label }: Props): JSX.Element => (
    <label className="ais-RefinementList-label mb-1">
      <input
        className="ais-ToggleRefinement-checkbox"
        type="checkbox"
        checked={currentRefinement}
        onChange={() => refine(!currentRefinement)}
      />
      <span className="ais-RefinementList-labelText">
        <span className="text-base ml-3 flex justify-between">
          {label}
          <span className="text-base ml-3">({count.unchecked || 0})</span>
        </span>
      </span>
    </label>
  ),
);

export default CustomToggleRefinement;
