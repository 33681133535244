import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Youtube = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M19.61 3.18c-3.60-.246-11.63-.245-15.23 0-3.89.266-4.35 2.62-4.38 8.81.029 6.18.484 8.54 4.38 8.81 3.6.24 11.62.246 15.23 0 3.89-.266 4.35-2.62 4.38-8.81-.029-6.18-.484-8.54-4.38-8.81zm-10.61 12.81v-8l8 3.99-8 4.00z"
    />
  </svg>
);

export default Youtube;
