import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const ErrorIcon = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M11.13 5C11.51 4.33 12.48 4.33 12.86 5L22.39 21.5C22.77 22.16 22.29 23 21.52 23H2.47C1.70 23 1.22 22.16 1.60 21.5L11.13 5Z"
        stroke={color}
        strokeWidth="2"
      />
      <rect x="11" y="9.5" width="2" height="8" rx="1" fill={color} />
      <circle cx="12" cy="19.5" r="1" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ErrorIcon;
