import type { IconProps } from 'components/ui/Icon';

const AmericanExpress = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg
    {...props}
    width="43"
    height="29"
    viewBox="0 0 43 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.90 0.61H4.09C2.17 0.61 0.61 2.19 0.61 4.14V25.33C0.61 27.29 2.17 28.87 4.09 28.87H38.90C40.82 28.87 42.38 27.29 42.38 25.33V4.14C42.38 2.19 40.82 0.61 38.90 0.61Z"
      fill="#016FD0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.57 24.26V14.19L42.23 14.21V16.99L40.19 19.21L42.23 21.44V24.28H38.97L37.24 22.34L35.52 24.29L24.57 24.26Z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.74 23.16V15.30H32.31V17.11H27.87V18.34H32.20V20.12H27.87V21.33H32.31V23.16H25.74Z"
      fill="#016FD0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.28 23.16L35.91 19.23L32.28 15.30H35.09L37.31 17.79L39.54 15.30H42.23V15.36L38.67 19.23L42.23 23.05V23.16H39.51L37.25 20.64L35.01 23.16H32.28Z"
      fill="#016FD0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.39 5.26H29.65L31.14 8.70V5.26H36.40L37.30 7.84L38.21 5.26H42.23V15.33H21.02L25.39 5.26Z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.20 6.35L22.76 14.20H25.12L25.76 12.63H29.28L29.92 14.20H32.34L28.92 6.35H26.20ZM26.49 10.87L27.52 8.37L28.55 10.87H26.49Z"
      fill="#016FD0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.31 14.20V6.35L35.62 6.36L37.32 11.19L39.04 6.35H42.23V14.20L40.18 14.22V8.83L38.24 14.20H36.37L34.39 8.81V14.20H32.31Z"
      fill="#016FD0"
    />
  </svg>
);

export default AmericanExpress;
