import type { IconProps } from 'components/ui/Icon';

const Mastercard = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg
    {...props}
    width="29"
    height="18"
    viewBox="0 0 29 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.74 15.35C13.27 16.61 11.37 17.37 9.30 17.37C4.67 17.37 0.92 13.58 0.92 8.89C0.92 4.21 4.67 0.41 9.30 0.41C11.37 0.41 13.27 1.18 14.74 2.44C16.20 1.18 18.10 0.41 20.18 0.41C24.81 0.41 28.56 4.21 28.56 8.89C28.56 13.58 24.81 17.37 20.18 17.37C18.10 17.37 16.20 16.61 14.74 15.35Z"
      fill="#ED0006"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.74 15.35C16.54 13.79 17.68 11.48 17.68 8.89C17.68 6.31 16.54 3.99 14.74 2.44C16.20 1.18 18.10 0.41 20.18 0.41C24.81 0.41 28.56 4.21 28.56 8.89C28.56 13.58 24.81 17.37 20.18 17.37C18.10 17.37 16.20 16.61 14.74 15.35Z"
      fill="#F9A000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.74 15.35C16.54 13.79 17.68 11.48 17.68 8.89C17.68 6.31 16.54 3.99 14.74 2.44C12.94 3.99 11.79 6.31 11.79 8.89C11.79 11.48 12.94 13.79 14.74 15.35Z"
      fill="#FF5E00"
    />
  </svg>
);

export default Mastercard;
