import type { IconProps } from 'components/ui/Icon';

const AU = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg">
    <g strokeWidth="1pt">
      <path d="M0 0h640v480H0z" fill="#006" />
      <path
        d="M0 0v27.95L307.03 250h38.64v-27.95L38.64 0H0zm345.68 0v27.95L38.64 250H0v-27.95L307.03 0h38.64z"
        fill="#fff"
      />
      <path
        d="M144.03 0v250h57.61V0h-57.61zM0 83.33v83.33h345.68V83.33H0z"
        fill="#fff"
      />
      <path
        d="M0 100v50h345.68v-50H0zM155.55 0v250h34.56V0h-34.56zM0 250l115.22-83.33h25.76L25.76 250H0zM0 0l115.22 83.33H89.46L0 18.63V0zm204.69 83.33L319.92 0h25.76L230.45 83.33H204.69zM345.68 250l-115.22-83.33h25.76l89.46 64.7V250z"
        fill="#c00"
      />
      <path
        d="M299.76 392.52l-43.65 3.79 6.01 43.40-30.18-31.76-30.18 31.76 6.01-43.40-43.65-3.79 37.68-22.36-24.24-36.49 40.97 15.51 13.42-41.71 13.42 41.71 40.97-15.51-24.24 36.49m224.44 62.37l-10.53-15.85 17.81 6.74 5.82-18.12 5.82 18.12 17.80-6.74-10.53 15.85 16.37 9.71-18.96 1.65 2.61 18.85-13.11-13.79-13.11 13.79 2.61-18.85-18.96-1.65m16.36-291.81l-10.53-15.85 17.81 6.74 5.82-18.12 5.82 18.12 17.80-6.74-10.53 15.85 16.37 9.71-18.96 1.65 2.61 18.85-13.11-13.79-13.11 13.79 2.61-18.85-18.96-1.65m-89.41 104.88l-10.53-15.85 17.80 6.74 5.82-18.12 5.82 18.12 17.80-6.74-10.53 15.85 16.37 9.72-18.96 1.65 2.61 18.85-13.11-13.79-13.11 13.79 2.61-18.85-18.96-1.65m216.21-37.92l-10.55-15.85 17.82 6.74 5.78-18.12 5.85 18.12 17.77-6.74-10.50 15.85 16.36 9.71-18.97 1.65 2.60 18.85-13.11-13.79-13.11 13.79 2.61-18.85-18.93-1.65m-22.25 73.39l-10.36 6.42 2.91-11.84-9.31-7.86 12.16-.896 4.60-11.29 4.60 11.29 12.16.897-9.31 7.86 2.91 11.84"
        fill="#fff"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

export default AU;
