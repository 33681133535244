import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const HouseAlternate = ({
  color = iconDefault.color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M17.51 7.79C17.51 7.79 17.51 7.79 17.51 7.79L10.17 0.45C9.85 0.13 9.44 -0.03 8.99 -0.03C8.55 -0.03 8.14 0.13 7.82 0.45L0.48 7.78C0.48 7.79 0.48 7.79 0.48 7.79C-0.16 8.44 -0.16 9.49 0.48 10.13C0.77 10.43 1.16 10.60 1.58 10.61C1.60 10.62 1.61 10.62 1.63 10.62H1.92V16.02C1.92 17.09 2.79 17.96 3.86 17.96H6.73C7.03 17.96 7.26 17.72 7.26 17.43V13.20C7.26 12.71 7.66 12.31 8.15 12.31H9.84C10.33 12.31 10.73 12.71 10.73 13.20V17.43C10.73 17.72 10.96 17.96 11.25 17.96H14.13C15.20 17.96 16.07 17.09 16.07 16.02V10.62H16.34C16.78 10.62 17.20 10.44 17.51 10.13C18.15 9.49 18.15 8.44 17.51 7.79ZM16.76 9.39C16.65 9.50 16.50 9.56 16.34 9.56H15.54C15.25 9.56 15.01 9.80 15.01 10.09V16.02C15.01 16.51 14.61 16.91 14.13 16.91H11.78V13.20C11.78 12.13 10.91 11.26 9.84 11.26H8.15C7.08 11.26 6.21 12.13 6.21 13.20V16.91H3.86C3.37 16.91 2.98 16.51 2.98 16.02V10.09C2.98 9.80 2.74 9.56 2.45 9.56H1.66C1.66 9.56 1.65 9.56 1.64 9.56C1.48 9.56 1.34 9.50 1.23 9.39C0.99 9.15 0.99 8.77 1.23 8.53C1.23 8.53 1.23 8.53 1.23 8.53L1.23 8.53L8.57 1.19C8.68 1.08 8.83 1.01 8.99 1.01C9.16 1.01 9.31 1.08 9.42 1.19L16.76 8.53C16.76 8.53 16.76 8.53 16.76 8.53C17.00 8.77 17.00 9.15 16.76 9.39Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HouseAlternate;
