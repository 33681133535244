import { PRODUCTION } from 'constants/environment';
import { Fragment, useContext } from 'react';
import config from 'config';
import { LocaleContext } from 'context/locale';
import type { ReactChild } from 'react';
import type { Locales } from 'locales';
import type { ConfigLocale } from 'types/PageData';

type TranslationFunction = (
  str: string,
  args?: Record<string, number | string | JSX.Element | undefined>,
  asString?: boolean,
  keyFallback?: boolean,
) => string;

type Translation = {
  t: TranslationFunction;
  locale: string;
};

export const useTranslation = (fallbackToKey = false): Translation => {
  // eslint-disable-next-line
  // @ts-ignore
  const { locale = config.defaultLocale, localesContent } =
    useContext(LocaleContext);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const t: TranslationFunction = (
    localeType,
    args?,
    asString = false,
    keyFallback = fallbackToKey,
  ) => {
    const localeObject: ConfigLocale | null =
      localesContent?.[locale as keyof Locales] || null;

    const defaultLocaleObject: ConfigLocale =
      localesContent?.[config.defaultLocale as keyof Locales] || null;

    const localeValue: string | null =
      localeObject?.[localeType as keyof ConfigLocale] || null;

    const defaultLocaleValue: string | null =
      defaultLocaleObject?.[localeType as keyof ConfigLocale] || null;

    const localeValueOrDefault = localeValue || defaultLocaleValue || '';

    if (!localeValue) {
      if (!fallbackToKey && !keyFallback) {
        if (!PRODUCTION) {
          console.warn(
            `Translation '${localeType}' for locale '${locale}' not found.`,
          );
        }

        return '';
      }
    }

    if (args) {
      const tokens = localeValueOrDefault
        .split(/(\{.*?\})/g)
        .map((token: string) => {
          if (/\{.*?\}/.test(token)) {
            const key = token.replace(/[{}]+/g, '');
            return args[key];
          }

          return token;
        });

      if (asString) {
        return tokens.join('');
      }

      return (
        <>
          {tokens.map(
            (token: boolean | ReactChild | null | undefined, i: number) => (
              <Fragment key={i}>{token}</Fragment>
            ),
          )}
        </>
      );
    }

    if ((fallbackToKey && keyFallback) || keyFallback) {
      if (!localeValueOrDefault) {
        return localeType;
      }
    }

    return localeValueOrDefault;
  };

  return {
    t,
    locale,
  };
};
