import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Twitter = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M24 4.55c-.883.39-1.83.656-2.82.775 1.01-.609 1.79-1.57 2.16-2.72-.951.56-2.00.974-3.12 1.19-.897-.957-2.17-1.55-3.59-1.55-3.17 0-5.51 2.96-4.79 6.04-4.09-.205-7.71-2.16-10.14-5.14-1.29 2.21-.669 5.10 1.52 6.57-.806-.026-1.56-.247-2.22-.616-.054 2.28 1.58 4.41 3.94 4.89-.693.18-1.45.232-2.22.084.62 1.95 2.44 3.37 4.6 3.41-2.07 1.62-4.67 2.34-7.29 2.04 2.17 1.39 4.76 2.21 7.54 2.21 9.14 0 14.30-7.72 13.99-14.64.962-.695 1.79-1.56 2.45-2.54z"
    />
  </svg>
);

export default Twitter;
