import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Heart = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M12 4.24c-3.14-5.40-12-3.82-12 2.94 0 4.66 5.57 9.42 12 15.80 6.43-6.38 12-11.14 12-15.80 0-6.79-8.87-8.30-12-2.94z"
    />
  </svg>
);

export default Heart;
