import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Email = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="1 -57 511.99 511"
    fill={color}
  >
    <g>
      <path
        d="m432 .5h-352c-44.11 0-80 35.88-80 80v236c0 44.11 35.88 80 80 80h352c24.53 0 47.37-11.02 62.66-30.26 5.51-6.94 6.62-18.84-2.17-27.21l-.996093-.941406c-.800782-.75-1.97-1.84-3.46-3.24-2.95-2.75-7.17-6.68-12.25-11.39-10.12-9.41-23.62-21.94-37.14-34.48-27.01-25.06-54.03-50.12-54.03-50.12-8.10-7.50-20.75-7.03-28.26 1.07-7.50 8.10-7.03 20.75 1.07 28.26 29.28 27.14 69.19 64.17 91.28 84.70-5.15 2.36-10.82 3.62-16.69 3.62h-352c-5.93 0-11.57-1.31-16.64-3.64l91.25-84.69c8.09-7.51 8.56-20.16 1.05-28.26-7.51-8.09-20.17-8.56-28.26-1.05l-87.24 80.98c-.089844-1.09-.148438-2.20-.148438-3.32v-236c0-1.17.0625-2.33.160156-3.48l151.23 139.19c3.69 3.39 8.52 5.28 13.54 5.28h102.13c5.01 0 9.85-1.88 13.54-5.28l151.22-139.16c.097656 1.14.160156 2.28.160156 3.44v156c0 11.04 8.95 20 20 20s20-8.95 20-20v-156c0-44.11-35.88-80-80-80zm-132.73 181h-86.52l-149.28-137.40c5.04-2.30 10.64-3.59 16.54-3.59h352c5.91 0 11.51 1.29 16.57 3.60zm0 0"
        data-original="#000000"
        className="active-path"
        data-old_color="#000000"
      />
    </g>
  </svg>
);

export default Email;
