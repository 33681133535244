import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Inserts = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="176"
    height="167"
    viewBox="0 0 176 167"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.4984 94.5872C69.0159 92.8271 69.9339 90.5498 69.9339 88.0689V69.2443H88.9152C89.6137 69.2443 90.2958 69.1741 90.9566 69.0383C92.2781 68.7668 93.5077 68.2426 94.5886 67.517C97.2954 65.6985 99.0819 62.6254 99.0819 59.1591V38.9911C99.0819 35.5248 97.2954 32.4517 94.5886 30.6355C93.5077 29.9099 92.2781 29.3833 90.9566 29.1118C90.2958 28.9784 89.6137 28.9082 88.9152 28.9082H79.2794C75.8834 31.1808 72.7801 33.8513 70.0307 36.8472C61.7873 45.8347 56.7559 57.7712 56.7559 70.8499C56.7559 74.4566 57.1382 77.9744 57.865 81.3704C58.3512 83.6313 58.9884 85.8408 59.7672 87.98C60.5483 90.1215 61.4687 92.1952 62.5213 94.1917C63.0452 95.191 63.6045 96.1694 64.1945 97.1266C64.6169 96.9207 65.0252 96.6866 65.4122 96.4292C66.1887 95.9096 66.8919 95.2893 67.4984 94.5872Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.279 106.128C143 107.431 141.65 108.665 140.236 109.826L128.543 94.135C126.263 91.0736 121.909 90.4253 118.819 92.6909C116.891 94.1022 115.912 96.321 116.009 98.5304C116.046 99.4151 116.254 100.3 116.644 101.128C116.839 101.545 117.078 101.948 117.363 102.331L128.373 117.102C122.064 119.878 115.093 121.435 107.761 121.435C89.3316 121.435 73.1563 111.691 64.1932 97.1261C63.6032 96.1689 63.0462 95.1905 62.52 94.1911C61.4674 92.1947 60.547 90.121 59.7659 87.9795C58.9871 85.8403 58.3499 83.6332 57.8661 81.3699C57.1392 77.9762 56.7569 74.4561 56.7569 70.8517C56.7569 57.7707 61.786 45.8365 70.0317 36.8466C72.7788 33.8508 75.8845 31.1826 79.2781 28.9077C87.4176 23.4543 97.221 20.2666 107.761 20.2666C135.884 20.2666 158.764 42.96 158.764 70.8517C158.764 75.7457 158.059 80.4805 156.744 84.9626C156.22 86.753 155.595 88.5061 154.882 90.2076C154.165 91.9138 153.36 93.5709 152.468 95.1765C151.576 96.7821 150.601 98.3338 149.544 99.8271C149.015 100.574 148.468 101.304 147.901 102.02C146.769 103.452 145.558 104.824 144.279 106.128ZM148.499 120.91C163.225 109.102 172.665 91.0523 172.665 70.8515C172.665 35.3555 143.548 6.47849 107.761 6.47849C93.6531 6.47849 80.593 10.9793 69.9354 18.5906V10.0829C69.9354 4.53588 65.3594 0 59.7663 0H39.4304C33.8373 0 29.2613 4.53588 29.2613 10.0829V28.9075H10.28C4.68927 28.9075 0.113281 33.4457 0.113281 38.9903V59.1607C0.113281 64.7054 4.68927 69.2436 10.28 69.2436H29.2613V88.0682C29.2613 93.6128 33.8373 98.1534 39.4304 98.1534H48.9977C59.3769 120.03 81.8084 135.222 107.761 135.222C118.192 135.222 128.047 132.753 136.791 128.4L163.459 164.181C164.823 166.009 166.923 166.976 169.054 166.976C170.489 166.976 171.938 166.538 173.179 165.628C176.266 163.364 176.92 159.046 174.638 155.987L148.499 120.91Z"
      fill={color}
    />
  </svg>
);

export default Inserts;
