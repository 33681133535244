import LazyLoad from 'react-lazyload';

type TrustBoxImplProps = {
  theme?: 'dark' | 'light';
  className?: string;
};

// Based on: https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-widget-to-a-single-page-application
const TrustBoxImpl = ({ theme = 'light' }: TrustBoxImplProps): JSX.Element => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <p style={{ textAlign: 'left' }}>
        <a
          // eslint-disable-next-line react/forbid-dom-props
          href="https://www.trustpilot.com/review/www.qunomedical.com?utm_medium=Trustbox&utm_source=Website"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <img
            src={`https://emailsignature.trustpilot.com/brand/n/2/logo${
              theme === 'dark' ? '_dark' : ''
            }.png`}
            height="32"
            alt="Trustpilot Logo"
            style={{ maxHeight: '32px', width: 'initial', margin: '0px' }}
          />

          <img
            src="https://emailsignature.trustpilot.com/newsletter/en-US/2/5cd35d84c4dd7a0001be3252/stars.png"
            height="32"
            alt="Trustpilot Stars"
            style={{ maxHeight: '32px', width: 'initial', margin: '5px 0px' }}
          />

          <img
            src={`https://emailsignature.trustpilot.com/newsletter/en-US/2/5cd35d84c4dd7a0001be3252/text${
              theme === 'dark' ? '_dark' : ''
            }@2x.png`}
            height="14"
            alt="TrustScore"
            style={{ maxHeight: '14px', width: 'initial', margin: '0px' }}
          />
        </a>
      </p>
    </div>
  );
};

type Props = Omit<TrustBoxImplProps, 'height'> & {
  overflow?: boolean;
  lazyLoadOffset?: number;
  height?: number;
};

const TrustBox = ({
  overflow = false,
  lazyLoadOffset = 100,
  height = 150,
  ...props
}: Props): JSX.Element => {
  return (
    <LazyLoad
      classNamePrefix="trust-box"
      overflow={overflow}
      offset={lazyLoadOffset}
      height={height}
    >
      <TrustBoxImpl {...props} />
    </LazyLoad>
  );
};

export default TrustBox;
