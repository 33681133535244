import type { IconProps } from 'components/ui/Icon';

const Messenger = ({
  color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 58 58"
      width="29"
      height="29"
      {...props}
    >
      <path
        d="M29 8C15.7 8 5 16.8 5 27.8c0 5.4 2.7 10.6 7.4 14.3l-.2 6.2c0 .6.2 1.1.7 1.4.3.2.6.3.9.3.2 0 .5-.1.7-.2l6.7-3.3c2.5.7 5.2 1.1 7.9 1.1 13.2 0 24-8.9 24-19.8C53 16.8 42.2 8 29 8zm0 36.4c-2.6 0-5.1-.4-7.6-1.1-.4-.1-.8-.1-1.2.1l-4.9 2.4.2-4.4c0-.5-.2-1-.6-1.3-4.3-3.2-6.8-7.6-6.8-12.3 0-9.2 9.4-16.6 20.9-16.6s20.9 7.5 20.9 16.6S40.5 44.4 29 44.4zm-1.8-23.1L15.1 34.2l11.1-5.6 5.7 5.8 11.9-13.1-10.8 6-5.8-6z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
};

export default Messenger;
