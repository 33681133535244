import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Oncology = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15.35" cy="9.35" r="2.85" stroke={color} strokeWidth="3" />
    <circle cx="25.61" cy="23.75" r="2.85" stroke={color} strokeWidth="3" />
    <circle cx="27.06" cy="36.8" r="2.36" stroke={color} strokeWidth="3" />
    <circle cx="35.76" cy="22.3" r="1.5" stroke={color} strokeWidth="2.8" />
    <circle cx="34.79" cy="31" r="1.5" stroke={color} strokeWidth="2.8" />
    <circle
      cx="20.3"
      cy="30.03"
      r="1.5"
      fill={color}
      stroke={color}
      strokeWidth="0.86"
    />
    <path
      d="M41.35 5.85C41.35 7.42 40.07 8.7 38.50 8.7C36.92 8.7 35.65 7.42 35.65 5.85C35.65 4.27 36.92 3 38.50 3C40.07 3 41.35 4.27 41.35 5.85Z"
      stroke={color}
      strokeWidth="3"
    />
    <circle cx="53.65" cy="26.65" r="2.85" stroke={color} strokeWidth="3" />
    <circle cx="43.35" cy="50.35" r="2.85" stroke={color} strokeWidth="3" />
    <circle cx="18.35" cy="52.35" r="2.85" stroke={color} strokeWidth="3" />
    <circle cx="4.35" cy="32.35" r="2.85" stroke={color} strokeWidth="3" />
    <line
      x1="8.12"
      y1="31.30"
      x2="15.77"
      y2="30.60"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="41.95"
      y1="27.43"
      x2="49.60"
      y2="26.74"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="18.06"
      y1="12.47"
      x2="21.73"
      y2="18.29"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="37.64"
      y1="9.75"
      x2="34.82"
      y2="16.64"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="23.14"
      y1="41.65"
      x2="20.32"
      y2="48.54"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="36.88"
      y1="40.11"
      x2="41.01"
      y2="46.31"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M38.66 16.01C35.83 13.89 32.32 12.63 28.51 12.63C19.17 12.63 11.60 20.20 11.60 29.55C11.60 38.89 19.17 46.46 28.51 46.46C37.85 46.46 45.43 38.89 45.43 29.55C45.43 27.40 45.03 25.34 44.30 23.45"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export default Oncology;
