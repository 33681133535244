import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Urology = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.15 34.38C12.18 36.42 15.79 36.64 18.05 34.39C19.09 33.35 19.66 31.96 19.66 30.50C19.66 29.03 19.09 27.65 18.05 26.61C17.25 25.81 16.6 24.88 16.11 23.87C15.86 23.37 15.86 22.78 16.11 22.28C16.35 21.77 16.81 21.41 17.36 21.29C18.18 21.10 18.78 20.35 18.78 19.50C18.78 18.65 18.18 17.89 17.36 17.71C16.81 17.59 16.35 17.22 16.11 16.72C15.86 16.21 15.86 15.63 16.11 15.12C16.6 14.11 17.25 13.19 18.05 12.39C19.09 11.35 19.66 9.97 19.66 8.50C19.66 7.03 19.09 5.65 18.05 4.61C15.90 2.46 12.30 2.46 10.15 4.61C6.18 8.58 4 13.87 4 19.50C4 25.13 6.18 30.41 10.15 34.38Z"
      stroke={color}
      strokeWidth="3"
    />
    <path
      d="M48.69 4.61C46.50 2.46 42.83 2.46 40.64 4.61C39.58 5.65 39 7.03 39 8.50C39 9.97 39.58 11.35 40.64 12.38C41.45 13.19 42.12 14.11 42.62 15.12C42.87 15.63 42.87 16.21 42.62 16.72C42.37 17.22 41.90 17.58 41.35 17.71C40.50 17.89 39.89 18.65 39.89 19.50C39.89 20.35 40.50 21.10 41.35 21.28C41.90 21.41 42.37 21.77 42.62 22.27C42.87 22.78 42.87 23.36 42.62 23.87C42.12 24.88 41.45 25.80 40.64 26.61C39.58 27.65 39.00 29.03 39.00 30.49C39.00 31.96 39.58 33.34 40.64 34.38C42.97 36.67 46.65 36.39 48.69 34.38C52.73 30.41 54.96 25.13 54.96 19.50C54.96 16.33 54.26 13.27 52.92 10.5"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M30.55 35.34C30.61 35.34 30.66 35.35 30.72 35.35C31.09 35.35 32.28 35.50 33.36 36.04C33.39 36.06 33.43 36.08 33.46 36.09C36.91 37.50 39.11 40.42 39.11 43.62C39.11 46.72 36.99 49.68 33.46 51.11C32.90 51.34 32.53 51.89 32.53 52.50V55.35C32.53 55.43 32.46 55.5 32.38 55.5H25.80C25.72 55.5 25.66 55.43 25.66 55.35V52.50C25.66 51.89 25.29 51.34 24.72 51.11C21.20 49.68 19.08 46.72 19.08 43.62C19.08 40.42 21.28 37.50 24.73 36.09C24.74 36.08 24.76 36.07 24.78 36.07C25.24 35.86 26.48 35.41 27.54 35.35C27.57 35.34 27.60 35.34 27.63 35.34C28.11 35.28 28.60 35.25 29.09 35.25C29.59 35.25 30.08 35.28 30.55 35.34Z"
      stroke={color}
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M25.81 35.20V23.23C25.81 21.02 24.02 19.23 21.81 19.23H19.52"
      stroke={color}
      strokeWidth="3"
    />
    <path
      d="M33.07 35.20V23.23C33.07 21.02 34.86 19.23 37.07 19.23H39.36"
      stroke={color}
      strokeWidth="3"
    />
  </svg>
);

export default Urology;
