import clsx from 'clsx';
import Link from 'components/ui/Link';
import { useTranslation } from 'hooks/useTranslation';
import { useRouter } from 'next/router';
import styles from './Navigation.module.scss';
import SubMenu from './SubMenu';
import type { MenuType } from './Header';

type Props = {
  onLinkClick?: () => void;
  className?: string;
  menuLinks: MenuType[];
};

const Navigation = ({
  onLinkClick,
  className,
  menuLinks,
}: Props): JSX.Element => {
  const { t, locale } = useTranslation();
  const router = useRouter();

  const filteredMenuLinks = menuLinks.filter(
    (link) => link?.menuItem?.name?.toLowerCase() !== t('search').toLowerCase(),
  );

  const currentMenuLocation = filteredMenuLinks.find((menu) => {
    if (
      router.asPath.startsWith(
        `/${router.query.locale}/${menu.menuItem.link}`,
      ) &&
      menu.menuItem.link
    ) {
      return menu;
    } else if (menu.subMenuItems.length > 0) {
      return menu.subMenuItems.find((subMenu) => {
        if (
          router.asPath.startsWith(`/${router.query.locale}/${subMenu.link}`)
        ) {
          return subMenu;
        }
      });
    }
  });

  const currentLocation = currentMenuLocation?.menuItem.link.toLowerCase();

  return (
    <nav
      className={clsx(
        'font-netto text-primary text-lg lg:text-base uppercase',
        styles.mainNavigation,
        className,
      )}
    >
      <ul className="flex flex-row items-center lg:px-0 lg:flex-row lg:items-center">
        {filteredMenuLinks.map((link) => {
          const { menuItem, additionalInfo, subMenuItems } = link;

          if (subMenuItems.length) {
            return (
              <li key={menuItem.name}>
                <SubMenu
                  menuItem={menuItem}
                  subMenu={subMenuItems}
                  currentLocation={currentLocation}
                />
              </li>
            );
          }

          return (
            <li
              className="flex flex-row text-base justify-center items-center"
              key={menuItem.name}
            >
              <Link href={`/${locale}/${menuItem.link}`}>
                <a
                  className={clsx(
                    'relative no-underline text-primary w-max',
                    currentLocation === menuItem.link.toLowerCase() &&
                      styles.active,
                  )}
                  onClick={onLinkClick}
                >
                  {menuItem.name}
                </a>
              </Link>
              {additionalInfo && (
                <span className={styles.reviewScoreBox}>{additionalInfo}</span>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navigation;
