import algoliasearch from 'algoliasearch/lite';
// eslint-disable-next-line
// @ts-ignore
import { findResultsState } from 'react-instantsearch-dom/server';
import DoctorList from 'components/ui/ContentBlocks/RefugeeDoctors';
import type { Entry } from 'contentful';
import type { IBlockGenericCtaFields } from 'types/contentful/generated';

export const searchClient = algoliasearch(
  process.env.ALGOLIA_APP_ID || '',
  process.env.ALGOLIA_API_KEY || '',
);

export const getUkraineDoctors = async (
  cta: Entry<IBlockGenericCtaFields>,
  entry: unknown,
): Promise<string> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const resultsState = await findResultsState(DoctorList, {
    searchClient,
    entry,
    cta,
    indexName: 'doctors_for_ukraine',
  });

  return JSON.stringify(resultsState);
};
