import { LINK_SHARE_IMAGE } from 'constants/image';
import clsx from 'clsx';
import Head from 'next/head';
import { useRouter } from 'next/router';
import QunosuiteBanner from 'components/Common/QunosuiteBanner';
import generateHrefLangTags from 'components/Shared/HrefLangTags';
import config from 'config';
import { BrandContext } from 'context/brand';
import { useTranslation } from 'hooks/useTranslation';
import { getProxyDomainByStage } from 'utils/url';
import Footer from './Footer';
import Header from './Header';
import PreviewBar from './PreviewBar';
import type { ReactNode } from 'react';
import type { AlternateSlug } from 'types/AlternateSlugs';
import type { Brand } from 'types/Brand';
import type { IFooter } from 'types/contentful/generated';
import type { FooterItemType } from 'types/Footer';

type Props = {
  children: ReactNode | ReactNode[];
  preview?: boolean;
  searchIndex?: boolean;
  searchFollow?: boolean;
  title: string;
  metaDescription?: string;
  alternateSlugs?: AlternateSlug[] | null;
  alternateSlugsPrefix?: string;
  ogImage?: string;
  footer: IFooter | FooterItemType | null | undefined;
  reducedHeader?: boolean;
  reducedFooter?: boolean;
  noMetaTitle?: string;
  showUfdBanner?: boolean;
  noLayout?: boolean;
  overflowXHidden?: boolean;
  absoluteFooter?: boolean;
  brand: Brand | null;
};

const Layout = ({
  children,
  preview,
  searchIndex = true,
  searchFollow = true,
  title,
  metaDescription,
  alternateSlugs,
  alternateSlugsPrefix,
  ogImage,
  footer,
  noMetaTitle = '',
  showUfdBanner,
  noLayout,
  overflowXHidden = true,
  brand,
}: Props): JSX.Element => {
  const { asPath } = useRouter();
  const { locale } = useTranslation();
  const pathWithoutParameters = asPath.replace(/\?.*|#.*/g, "$'");

  return (
    <div
      className={clsx('page-wrapper', overflowXHidden && 'overflow-x-hidden')}
    >
      <Head>
        <meta name="viewport" content="width=device-width" />
        <meta
          name="robots"
          content={`${searchIndex ? 'index' : 'noindex'}, ${
            searchFollow ? 'follow' : 'nofollow'
          }`}
        />
        <meta
          name="google-site-verification"
          content={config.defaults.googleVerification}
        />
        {title && <title>{title}</title>}
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        {alternateSlugs &&
          generateHrefLangTags(alternateSlugs, alternateSlugsPrefix)}
        <link
          rel="canonical"
          /* eslint-disable-next-line react/forbid-dom-props */
          href={`${getProxyDomainByStage(
            process.env.STAGE as string,
          )}${pathWithoutParameters}`}
        />
        <meta
          property="og:image"
          content={brand?.ogImage?.url || ogImage || LINK_SHARE_IMAGE}
        />
        {metaDescription && (
          <meta property="og:description" content={metaDescription} />
        )}
        <link
          rel="icon"
          type="image/png"
          // eslint-disable-next-line react/forbid-dom-props
          href={'/favicon.ico'}
        />
        <meta
          property="og:url"
          content={`https://${config.app.domains.proxy.production}${pathWithoutParameters}`}
        />
        <meta property="og:title" content={title || brand?.name} />
        <link
          rel="preconnect" // eslint-disable-next-line react/forbid-dom-props
          href="//app.usercentrics.eu"
        />
        <link
          rel="preconnect"
          // eslint-disable-next-line react/forbid-dom-props
          href="//api.usercentrics.eu"
        />
        <link
          rel="preload"
          // eslint-disable-next-line react/forbid-dom-props
          href="//app.usercentrics.eu/browser-ui/latest/loader.js"
          as="script"
        />
        <link
          rel="preload"
          // eslint-disable-next-line react/forbid-dom-props
          href="/v2/fonts/adelle-sans-bold/adelle-sans-bold.subset.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="crossorigin"
        />
        <link
          rel="preload"
          // eslint-disable-next-line react/forbid-dom-props
          href="/v2/fonts/adelle-sans-regular/adelle-sans-regular.subset.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="crossorigin"
        />
        <link
          rel="preload"
          // eslint-disable-next-line react/forbid-dom-props
          href="/v2/fonts/nettoweb-black/nettoweb-black.subset.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="crossorigin"
        />
        <link
          rel="preload"
          // eslint-disable-next-line react/forbid-dom-props
          href="/v2/fonts/nettoweb-regular/nettoweb-regular.subset.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="crossorigin"
        />
      </Head>
      <BrandContext.Provider value={{ brand }}>
        {preview && <PreviewBar />}
        {config.homepage.headerReviewsLink.includes(locale) && showUfdBanner ? (
          <QunosuiteBanner />
        ) : null}
        {!noLayout && (
          <Header
            brand={brand}
            languageSwitchSlugs={{
              slugs: alternateSlugs,
              prefix: alternateSlugsPrefix,
            }}
            title={title || ''}
            noMetaTitle={noMetaTitle || ''}
          />
        )}
        <main>{children}</main>
        {!noLayout && (
          <Footer brand={brand} fields={(footer as FooterItemType) || null} />
        )}
      </BrandContext.Provider>
    </div>
  );
};

export default Layout;
