import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Headset = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="31"
    height="32"
    viewBox="0 0 31 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.63 11.10C27.63 5.24 22.19 0.5 15.5 0.5C8.80 0.5 3.36 5.24 3.36 11.10C1.63 11.29 0 12.28 0 14.58V20.11C0 22.71 2.07 23.65 4.04 23.65H5.99C6.36 23.65 6.67 23.38 6.67 23.06V11.65C6.67 11.33 6.36 11.06 5.99 11.06H4.71C4.71 5.86 9.54 1.64 15.5 1.64C21.45 1.64 26.28 5.86 26.28 11.06H25.00C24.63 11.06 24.32 11.33 24.32 11.65V23.04C24.32 23.37 24.63 23.63 25.00 23.63H26.28C26.09 27.68 23.87 28.63 19.42 28.80V28.40C19.42 27.75 18.82 27.22 18.08 27.22H13.84C13.09 27.22 12.49 27.75 12.49 28.40V30.32C12.49 30.97 13.09 31.5 13.84 31.5H18.09C18.83 31.5 19.44 30.97 19.44 30.32V29.98C23.71 29.80 27.40 29.03 27.63 23.59C29.36 23.41 31 22.42 31 20.12V14.58C31 12.27 29.36 11.29 27.63 11.10ZM5.32 12.20V22.45H4.00C3.01 22.45 1.31 22.15 1.31 20.10V14.56C1.31 12.53 2.99 12.20 4.00 12.20H5.32ZM18.09 29.43V30.32H13.84V28.41H18.09V29.43ZM29.65 20.11C29.65 22.15 27.97 22.47 26.95 22.47H25.67V12.24H26.99C27.98 12.24 29.68 12.55 29.68 14.60L29.65 20.11Z"
      fill={color}
    />
    <path
      opacity="0.5"
      d="M0.54 15.12C0.54 13.46 1.88 12.12 3.54 12.12H6.08V22.78H3.54C1.88 22.78 0.54 21.43 0.54 19.78V15.12Z"
      fill={color}
    />
    <path
      opacity="0.5"
      d="M30.46 15.12C30.46 13.46 29.11 12.12 27.46 12.12H24.92V22.78H27.46C29.11 22.78 30.46 21.43 30.46 19.78V15.12Z"
      fill={color}
    />
  </svg>
);

export default Headset;
