import { Direction } from 'types/Utilities';
import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Cross = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" {...props}>
    <path
      transform={`${getRotation(Direction.RIGHT, 25, 25)} translate(-1217 -35)`}
      fill={color || 'currentColor'}
      d="M1232.46,47.49 L1241.38,38.58 C1242.20,37.76 1242.20,36.43 1241.38,35.61 C1240.56,34.79 1239.23,34.79 1238.41,35.61 L1229.49,44.52 L1220.58,35.61 C1219.76,34.79 1218.43,34.79 1217.61,35.61 C1216.79,36.43 1216.79,37.76 1217.61,38.58 L1226.52,47.49 L1217.61,56.41 C1216.79,57.23 1216.79,58.56 1217.61,59.38 C1218.43,60.20 1219.76,60.20 1220.58,59.38 L1229.49,50.46 L1238.41,59.38 C1239.23,60.20 1240.56,60.20 1241.38,59.38 C1242.20,58.56 1242.20,57.23 1241.38,56.41 L1232.46,47.49 L1232.46,47.49 Z"
      fillRule="evenodd"
    />
  </svg>
);

export default Cross;
