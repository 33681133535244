export const CLOUDINARY_UPLOAD_URL =
  'https://res.cloudinary.com/junomedical/image/upload';

export const LINK_SHARE_IMAGE = `${CLOUDINARY_UPLOAD_URL}/link-share-image-new.png`;

export const UPPY_MAX_FILE_SIZE_BYTES = 15000000;

export enum IMGS_PATH {
  CONTENTFUL = 'https://images.ctfassets.net/kfkw517g6gvn',
  PUBLIC = '/v2/images',
}

export const INVESTORS = [
  {
    alt: 'Kima Ventures',
    height: 64,
    src: 'kima_ventures.png',
    width: 115,
  },
  {
    alt: 'Project A',
    height: 56,
    src: 'projecta.png',
    width: 159,
  },
  {
    alt: '500 Startups',
    height: 56,
    src: '500_startups.png',
    width: 134,
  },
  {
    alt: 'MS AD',
    height: 64,
    src: 'ms_ad.png',
    width: 136,
  },
];
