import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Envelope = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill={color}
  >
    <path
      d="M14.00 11.70C13.40 12.10 12.71 12.31 12 12.31C11.28 12.31 10.59 12.10 9.99 11.70L0.15 5.14C0.10 5.11 0.05 5.07 0 5.03V15.77C0 17.01 0.99 17.98 2.20 17.98H21.79C23.02 17.98 24 16.98 24 15.77V5.03C23.94 5.07 23.89 5.11 23.83 5.14L14.00 11.70Z"
      fill={color}
    />
    <path
      d="M0.93 3.97L10.77 10.53C11.14 10.78 11.57 10.90 12 10.90C12.42 10.90 12.85 10.78 13.22 10.53L23.06 3.97C23.64 3.58 24 2.92 24 2.22C24 1.00 23.00 0.01 21.79 0.01H2.20C0.99 0.01 0 1.00 0 2.22C0 2.92 0.35 3.58 0.93 3.97Z"
      fill={color}
    />
  </svg>
);

export default Envelope;
