import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const QuoteAlternate = ({
  color = iconDefault.color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="68"
    height="57"
    viewBox="0 0 68 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.724689 55.5151C0.241563 54.3838 0 52.2828 0 49.2121V36.8485C0 17.2929 9.90409 5.0101 29.7123 0C32.1279 3.07071 33.5773 5.01011 34.0604 5.81819C34.5435 6.46465 34.7851 7.67677 34.7851 9.45455C29.7928 11.5556 25.3641 14.4647 21.4991 18.1818C17.6341 21.899 15.6211 25.5354 15.46 29.0909L23.1901 28.6061C24.9615 28.4444 26.0888 28.9293 26.5719 30.0606C27.2161 31.1919 27.5382 33.2929 27.5382 36.3636V48.7273C27.5382 51.4747 27.2161 53.3333 26.5719 54.303C26.0888 55.2727 24.8005 55.9192 22.7069 56.2424L4.34814 56.9697C2.57667 57.1313 1.36886 56.6465 0.724689 55.5151ZM33.9396 55.5151C33.4565 54.3838 33.2149 52.2828 33.2149 49.2121V36.8485C33.2149 17.2929 43.119 5.0101 62.9272 0C65.3428 3.07071 66.7922 5.01011 67.2753 5.81819C67.7584 6.46465 68 7.67677 68 9.45455C63.0077 11.5556 58.579 14.4647 54.714 18.1818C50.849 21.899 48.836 25.5354 48.675 29.0909L56.405 28.6061C58.1764 28.4444 59.3037 28.9293 59.7869 30.0606C60.431 31.1919 60.7531 33.2929 60.7531 36.3636V48.7273C60.7531 51.4747 60.431 53.3333 59.7869 54.303C59.3037 55.2727 58.0154 55.9192 55.9218 56.2424L37.5631 56.9697C35.7916 57.1313 34.5838 56.6465 33.9396 55.5151Z"
      fill={color}
    />
  </svg>
);

export default QuoteAlternate;
