import config from 'config';
import type { SetStateAction } from 'react';

type Suggestion = {
  sub_title?: string;
  title?: string;
  url?: string;
};

/**
 * Get search api result for current search term and locale
 *
 * @param searchTerm - the current search term
 * @param locale - the current locale
 */
export const getResults = async (
  searchTerm = '',
  locale: string,
): Promise<SetStateAction<Suggestion[] | null>> => {
  const response = await fetch(
    `https://www2.qunomedical.com/${locale}/api/treatments?search=${searchTerm}`,
  );

  const results = await response.json();
  return results.results;
};

/**
 * Get recent search terms from config
 *
 * @returns An array of search terms
 */
export const getRecentSearchResults = (
  locale: string,
): { title: string; sub_title: string; url: string }[] => {
  return config.search.recent[locale as keyof typeof config.search.recent];
};

/**
 * Highlight the search term within the result item
 *
 * @param searchTerm - the current search term
 * @param item - search result item
 */
export const highlightResultItem = (searchTerm = '', item = ''): string => {
  return item.replace(
    new RegExp(`(${searchTerm})`, 'i'),
    '<span class="border-b-2 font-bold border-secondary ">$1</span>',
  );
};

/**
 * Store current search term in locale storage
 */
export const persistSearchTerm = (searchTerm = ''): void | boolean => {
  try {
    localStorage.setItem('currentSearchTerm', searchTerm);
  } catch (error) {
    return false;
  }
};

/**
 * Get persisted search term from locale storate
 */
export const getPersistedSearchTerm = (): string => {
  return localStorage.getItem('currentSearchTerm') || '';
};
