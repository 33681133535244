import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Tick = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2929 5.29289C19.6834 4.90237 20.3166 4.90237 20.7071 5.29289C21.0976 5.68342 21.0976 6.31658 20.7071 6.70711L8.70711 18.7071C8.31658 19.0976 7.68342 19.0976 7.29289 18.7071L2.29289 13.7071C1.90237 13.3166 1.90237 12.6834 2.29289 12.2929C2.68342 11.9024 3.31658 11.9024 3.70711 12.2929L8 16.5858L19.2929 5.29289Z"
      fill="black"
    />
    <mask
      id="mask0_645_3683"
      maskUnits="userSpaceOnUse"
      x="2"
      y="5"
      width="19"
      height="14"
    >
      <path
        d="M19.2929 5.29289C19.6834 4.90237 20.3166 4.90237 20.7071 5.29289C21.0976 5.68342 21.0976 6.31658 20.7071 6.70711L8.70711 18.7071C8.31658 19.0976 7.68342 19.0976 7.29289 18.7071L2.29289 13.7071C1.90237 13.3166 1.90237 12.6834 2.29289 12.2929C2.68342 11.9024 3.31658 11.9024 3.70711 12.2929L8 16.5858L19.2929 5.29289Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_645_3683)">
      <rect width="30" height="30" fill={color} />
    </g>
  </svg>
);

export default Tick;
