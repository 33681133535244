import AmericanExpress from './AmericanExpress';
import ApplePay from './ApplePay';
import BankTransfer from './BankTransfer';
import Maestro from './Maestro';
import Mastercard from './Mastercard';
import Paypal from './Paypal';
import Visa from './Visa';
import type { IconMap } from 'components/ui/Icon';

export const Payment: IconMap = {
  AmericanExpress,
  ApplePay,
  BankTransfer,
  Maestro,
  Mastercard,
  Paypal,
  Visa,
};
