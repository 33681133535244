import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Free = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 0C10.76 0 0 10.76 0 24C0 37.23 10.76 48 24 48C37.23 48 48 37.23 48 24C48 10.76 37.23 0 24 0ZM4.34 24C4.34 19.35 5.96 15.10 8.64 11.77L13.89 17.02C14.60 15.66 15.51 14.45 16.57 13.49L11.77 8.69C15.10 6.01 19.40 4.39 24 4.39C34.81 4.39 43.65 13.18 43.65 24.05C43.65 28.69 42.03 32.94 39.36 36.27L34.76 31.68C34.81 31.88 34.81 32.08 34.81 32.28C34.81 33.09 34.50 33.85 33.95 34.40C33.49 34.86 33.04 35.26 32.53 35.62L36.27 39.36C32.89 42.03 28.64 43.65 24.05 43.65C13.18 43.65 4.34 34.81 4.34 24Z"
      fill={color}
    />
    <path
      d="M25.11 16.37C26.57 16.37 28.04 16.97 29.20 18.13C29.45 18.39 29.81 18.39 30.06 18.13L32.18 16.16C32.33 16.06 32.38 15.86 32.38 15.71C32.38 15.56 32.33 15.36 32.18 15.25C30.21 13.28 27.68 12.17 25.06 12.17C20.71 12.17 16.77 15.20 15.20 19.70H12.88C12.53 19.70 12.22 20.00 12.22 20.36V22.28C12.22 22.63 12.53 22.93 12.88 22.93H14.50C14.45 23.29 14.45 23.64 14.45 23.94C14.45 24.30 14.45 24.60 14.50 24.96H12.88C12.53 24.96 12.22 25.26 12.22 25.61V27.53C12.22 27.89 12.53 28.19 12.88 28.19H15.20C16.77 32.74 20.71 35.72 25.06 35.72C27.68 35.72 30.21 34.61 32.18 32.64C32.28 32.53 32.38 32.33 32.38 32.18C32.38 32.03 32.28 31.83 32.18 31.73L30.06 29.81C29.81 29.55 29.45 29.60 29.20 29.86C28.04 30.97 26.62 31.62 25.11 31.62C22.98 31.62 21.01 30.36 19.80 28.24H25.31C25.66 28.24 25.97 27.94 25.97 27.58V25.66C25.97 25.31 25.66 25.01 25.31 25.01H18.79C18.74 24.65 18.74 24.30 18.74 24C18.74 23.69 18.74 23.34 18.79 22.98H25.31C25.66 22.98 25.97 22.68 25.97 22.33V20.41C25.97 20.05 25.66 19.75 25.31 19.75H19.85C21.01 17.68 23.04 16.37 25.11 16.37Z"
      fill={color}
    />
  </svg>
);

export default Free;
