import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line
// @ts-ignore
import { Configure, InstantSearch, SearchBox } from 'react-instantsearch-dom';
import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';
import tailwind from 'utils/breakpoints';
import { useAnalytics } from 'hooks/useAnalytics';
import { useArrows } from 'hooks/useArrows';
import { useTranslation } from 'hooks/useTranslation';
import { searchClient } from 'services/algolia';
import * as searchService from 'services/search';
import Results from './Results';
import type { FormEvent } from 'react';

type Props = {
  open: boolean;
  onClose?: () => void;
  openIdentifier?: string;
};

const Search = ({
  open = false,
  onClose,
  openIdentifier,
  ...props
}: Props): JSX.Element | null => {
  const { locale, t } = useTranslation();
  const { trackEvent } = useAnalytics();
  const [isOpen, setIsOpen] = useState(open);
  const [isSearchEmpty, setIsSearchEmpty] = useState(true);
  const searchContainerRef = useRef<HTMLDivElement | null>(null);
  const suggestions = searchService.getRecentSearchResults(locale);

  const [focus] = useArrows((suggestions && suggestions.length) || null);

  useEffect(() => {
    setIsOpen(open);
    if (open) {
      document.body.classList.add('overflow-hidden');
    }
  }, [open]);

  const handleEsc = (): void => {
    closeSearch();
  };

  const goToSelection = (): void => {
    const selection: HTMLAnchorElement | null =
      document.querySelector('.bg-disabled-bg');
    if (selection && selection.href) {
      window.location.href = selection.href;
    }
  };

  const keyListenersMap = new Map([
    ['Escape', handleEsc],
    ['Enter', goToSelection],
  ]);

  const keyListener = (event: KeyboardEvent): void => {
    keyListenersMap.get(event.code)?.();
  };

  useEffect(() => {
    if (searchContainerRef.current) {
      // if (
      //   searchService.getPersistedSearchTerm() &&
      //   searchContainerRef.current.value === ''
      // ) {
      //   searchContainerRef.current.value =
      //     searchService.getPersistedSearchTerm();
      //   //requestResults();
      // } else {
      //   setSuggestions(searchService.getRecentSearchResults(locale));
      // }
      // searchContainerRef.current.focus();
      window.addEventListener('keydown', keyListener);
    }

    return () => window.removeEventListener('keydown', keyListener);
  }, [isOpen]);

  const closeSearch = (): void => {
    setIsOpen(false);
    setIsSearchEmpty(true);
    // setSuggestions(null);
    // setFocus(null);
    // if (searchContainerRef.current) {
    //   // TODO: look for input
    //   searchContainerRef.current.value = '';
    // }
    document.body.classList.remove('overflow-hidden');
    onClose && onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className="bg-secondary fixed left-0 right-0 z-30 top-0 bottom-0 quno-search"
      ref={searchContainerRef}
    >
      <InstantSearch
        indexName={`quno_search`}
        searchClient={searchClient}
        {...props}
      >
        <div className="max-w-screen-xl mx-auto z-10 flex lg:justify-between items-center p-4 md:px-4">
          <Link href={`/${locale}`}>
            <a>
              <Icon
                type="Qunomedical"
                color={tailwind.theme.backgroundColor.siam}
                className="w-56 hidden lg:block cursor-pointer"
              />
            </a>
          </Link>
          <div className="rounded-bl-24p p-2 mx-auto w-full lg:w-4/5 lg:ml-6 px-6 flex items-center justify-between bg-grandis-light">
            <div>
              <Icon type="Search" color={tailwind.theme.backgroundColor.siam} />
            </div>

            <Configure hitsPerPage={25} filters={`locale:${locale}`} />
            <SearchBox
              submit=""
              className="w-full relative mx-4"
              translations={{
                placeholder: t('search.placeholder'),
              }}
              onReset={() => setIsSearchEmpty(true)}
              onChange={(element: FormEvent<HTMLInputElement>) => {
                if (element.currentTarget?.value === '') {
                  setIsSearchEmpty(true);
                } else if (isSearchEmpty) {
                  setIsSearchEmpty(false);
                }
              }}
            />
            <div>
              <Icon
                type="Cross"
                color={tailwind.theme.backgroundColor.siam}
                className="w-6 cursor-pointer"
                onClick={closeSearch}
              />
            </div>
          </div>
        </div>

        <div className="bg-white h-screen -mt-2 overflow-auto">
          <div className="max-w-screen-xl mx-auto lg:flex justify-end p-5 md:px-4">
            <div className="w-full lg:w-4/5 mb-20">
              {isSearchEmpty && (
                <p className="text-navy font-bold uppercase p-2">
                  {t('search.latestSearches')}
                </p>
              )}

              {!isSearchEmpty && <Results openIdentifier={openIdentifier} />}

              {isSearchEmpty && suggestions.length >= 1
                ? suggestions.map((suggestion, i) => (
                    <div key={i}>
                      <a
                        key={i}
                        className={clsx(
                          'text-charcoal normal-case font-adelle border-b border-silver flex items-center justify-between p-2 hover:bg-disabled-bg cursor-pointer transition-colors duration-300 ease-in-out',
                          focus === i && 'bg-disabled-bg',
                        )}
                        title={suggestion.title}
                        // eslint-disable-next-line react/forbid-dom-props
                        href={suggestion.url}
                        onClick={() =>
                          trackEvent({
                            category: 'SearchBar',
                            action: 'Form|Submit',
                            label: `Form|SearchBar|${openIdentifier}|Submit`,
                          })
                        }
                      >
                        <span>
                          <span className="block text-xl mb-1">
                            <span key={i}>{suggestion.title}</span>
                          </span>
                          <span className="block text-sm text-silver">
                            {suggestion.title}
                          </span>
                        </span>
                        <Icon
                          type="Angle"
                          color={tailwind.theme.backgroundColor['silver-light']}
                        />
                      </a>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </InstantSearch>
    </div>
  );
};

export default Search;
