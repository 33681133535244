import type { IconProps } from 'components/ui/Icon';

const House = ({
  color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="13"
    height="13"
    viewBox="0 0 13 13"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
  >
    <path
      fill={color || 'currentColor'}
      d="M12.16 6.26C12.16 6.39 12.13 6.53 12.06 6.64C11.99 6.76 11.89 6.86 11.77 6.93C11.66 6.99 11.52 7.03 11.39 7.03C11.25 7.03 11.12 6.99 11.00 6.92V11.64C11.00 11.85 10.92 12.05 10.77 12.19C10.63 12.34 10.43 12.42 10.23 12.42H8.58C8.37 12.42 8.18 12.34 8.03 12.19C7.89 12.05 7.80 11.85 7.80 11.64V8.04H5.19V11.64C5.19 11.85 5.11 12.05 4.96 12.19C4.82 12.34 4.62 12.42 4.42 12.42H2.76C2.56 12.42 2.36 12.34 2.22 12.19C2.07 12.05 1.99 11.85 1.99 11.64V6.92C1.83 7.02 1.64 7.05 1.45 7.01C1.27 6.98 1.10 6.88 0.99 6.73C0.87 6.58 0.82 6.39 0.83 6.20C0.84 6.02 0.92 5.84 1.05 5.71L5.81 0.95L5.96 0.80C6.03 0.73 6.12 0.67 6.21 0.63C6.30 0.59 6.40 0.57 6.49 0.57C6.59 0.57 6.69 0.59 6.78 0.63C6.87 0.67 6.96 0.73 7.02 0.80L7.17 0.95L11.94 5.71C12.01 5.78 12.07 5.86 12.10 5.96C12.14 6.05 12.16 6.15 12.16 6.26Z"
    />
  </svg>
);

export default House;
