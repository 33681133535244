import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Copy = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.71 16H3.75C2.37 16 1.25 14.87 1.25 13.5V5.03C1.25 3.65 2.37 2.53 3.75 2.53H9.71C11.09 2.53 12.21 3.65 12.21 5.03V13.5C12.21 14.87 11.09 16 9.71 16ZM3.75 3.78C3.06 3.78 2.5 4.34 2.5 5.03V13.5C2.5 14.18 3.06 14.75 3.75 14.75H9.71C10.40 14.75 10.96 14.18 10.96 13.5V5.03C10.96 4.34 10.40 3.78 9.71 3.78H3.75ZM14.71 11.93V2.5C14.71 1.12 13.59 0 12.21 0H5.28C4.93 0 4.65 0.27 4.65 0.62C4.65 0.97 4.93 1.25 5.28 1.25H12.21C12.90 1.25 13.46 1.81 13.46 2.5V11.93C13.46 12.28 13.74 12.56 14.09 12.56C14.43 12.56 14.71 12.28 14.71 11.93Z"
      fill={color}
    />
  </svg>
);

export default Copy;
