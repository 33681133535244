import type { IconProps } from 'components/ui/Icon';

const HU = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 320h640v160.00H0z" fill="#416c4b" />
    <path d="M0 0h640v160H0z" fill="#c7293c" />
    <path d="M0 160h640v160H0z" fill="#ffffff" />
  </svg>
);

export default HU;
