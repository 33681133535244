import clsx from 'clsx';
// eslint-disable-next-line
// @ts-ignore
import { MenuSelect, Panel, SearchBox } from 'react-instantsearch-dom';
import CustomToggleRefinement from 'components/Shared/CustomToggleRefinement';
import Input from 'components/ui/Form/Input';

const translations: Record<
  string,
  Record<
    | 'language'
    | 'location'
    | 'seeAll'
    | 'speciality'
    | 'german'
    | 'english'
    | 'russian'
    | 'ukrainian'
    | 'inPractice'
    | 'homeCall'
    | 'county'
    | 'postalCode'
    | 'city'
    | 'search',
    string
  >
> = {
  DE: {
    language: 'Sprache der Ärzte',
    location: 'Ort',
    seeAll: 'Alle',
    speciality: 'Spezialität',
    german: 'Deutsch',
    english: 'Englisch',
    russian: 'Russisch',
    ukrainian: 'Ukrainisch',
    inPractice: 'Praxis',
    homeCall: 'Zuhause',
    county: 'Bezirk',
    postalCode: 'PLZ',
    city: 'Stadt',
    search: 'Suche',
  },
  EN: {
    language: `Doctor's language`,
    location: 'Visit',
    seeAll: 'See all',
    speciality: 'Speciality',
    german: 'German',
    english: 'English',
    russian: 'Russian',
    ukrainian: 'Ukrainian',
    inPractice: 'In Practice',
    homeCall: 'Home Call',
    county: 'District',
    postalCode: 'ZIP',
    city: 'City',
    search: 'Search',
  },
  RU: {
    language: 'Язык доктора',
    location: 'приём',
    seeAll: 'Все',
    speciality: 'Специальность',
    german: 'Немецкий',
    english: 'Английский',
    russian: 'Русский',
    ukrainian: 'Украинский',
    inPractice: 'в клинике',
    homeCall: 'на дому',
    county: 'Район',
    postalCode: 'Индекс',
    city: 'Город',
    search: 'Поиск',
  },
  UA: {
    language: 'Мова доктора',
    location: 'прийом',
    seeAll: 'всі',
    speciality: 'Спеціальність',
    german: 'Німецька',
    english: 'Англійська',
    russian: 'Російська',
    ukrainian: 'Український',
    inPractice: 'у клініці',
    homeCall: 'додому',
    county: 'Район',
    postalCode: 'Індекс',
    city: 'Місто',
    search: 'Пошук',
  },
};

type MenuSelectItem = {
  label: string;
};

type Props = {
  locale?: string;
  onPostcodeChange: (value: string) => void;
  zip?: string | null;
};

const Filters = ({
  locale = 'EN',
  onPostcodeChange,
  zip = null,
}: Props): JSX.Element => {
  return (
    <>
      <Panel
        header={
          <div className="uppercase text-navy font-bold mt-5 mb-2">
            {translations[locale].postalCode}
          </div>
        }
      >
        <Input
          className="relative"
          onChange={(element) => {
            onPostcodeChange(element.currentTarget.value);
          }}
          minLength={5}
          maxLength={5}
          useGreyBackground
          defaultValue={zip || ''}
        />
      </Panel>
      <Panel
        header={
          <div className="uppercase text-navy font-bold mt-5 mb-2">
            {translations[locale].city}
          </div>
        }
      >
        <div className="relative">
          <MenuSelect
            attribute="City"
            translations={{
              seeAllOption: translations[locale].seeAll,
            }}
            limit={100}
            transformItems={(items: MenuSelectItem[]) => {
              items.sort((a, b) => {
                if (a.label < b.label) {
                  return -1;
                }

                if (a.label > b.label) {
                  return 1;
                }

                return 0;
              });

              return items.map((item) => ({
                ...item,
              }));
            }}
          />
          <svg
            className="absolute top-0 right-0 pointer-events-none"
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            style={{ top: '16px', right: '9px' }}
          >
            <path
              fill="#909090"
              d="M4.51 7.54c0.43-0.44 1.04-0.48 1.57 0l3.90 3.74 3.90-3.74c0.53-0.48 1.14-0.44 1.57 0 0.43 0.44 0.40 1.19 0 1.61-0.40 0.41-4.69 4.50-4.69 4.50-0.21 0.22-0.50 0.33-0.78 0.33s-0.57-0.11-0.78-0.33c0 0-4.28-4.08-4.69-4.50s-0.43-1.17 0-1.61z"
            />
          </svg>
        </div>
      </Panel>
      <Panel
        header={
          <div className="uppercase text-navy font-bold mt-5 mb-2">
            {translations[locale].search}
          </div>
        }
      >
        <SearchBox
          submit=""
          className="mb-6 relative"
          translations={{
            placeholder: '',
          }}
        />
      </Panel>
      <Panel
        header={
          <div className="uppercase text-navy font-bold mt-5 mb-2">
            {translations[locale].speciality}
          </div>
        }
      >
        <div className={clsx(locale !== 'DE' && 'hidden', 'relative')}>
          <MenuSelect
            attribute="Specialty (DE)"
            translations={{
              seeAllOption: translations[locale].seeAll,
            }}
            limit={100}
            transformItems={(items: MenuSelectItem[]) => {
              items.sort((a, b) => {
                if (a.label < b.label) {
                  return -1;
                }

                if (a.label > b.label) {
                  return 1;
                }

                return 0;
              });

              return items.map((item) => ({
                ...item,
              }));
            }}
          />
          <svg
            className="absolute top-0 right-0 pointer-events-none"
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            style={{ top: '16px', right: '9px' }}
          >
            <path
              fill="#909090"
              d="M4.51 7.54c0.43-0.44 1.04-0.48 1.57 0l3.90 3.74 3.90-3.74c0.53-0.48 1.14-0.44 1.57 0 0.43 0.44 0.40 1.19 0 1.61-0.40 0.41-4.69 4.50-4.69 4.50-0.21 0.22-0.50 0.33-0.78 0.33s-0.57-0.11-0.78-0.33c0 0-4.28-4.08-4.69-4.50s-0.43-1.17 0-1.61z"
            />
          </svg>
        </div>
        <div className={clsx(locale !== 'EN' && 'hidden', 'relative')}>
          <MenuSelect
            attribute="Specialty (EN)"
            translations={{
              seeAllOption: translations[locale].seeAll,
            }}
            limit={100}
            transformItems={(items: MenuSelectItem[]) => {
              items.sort((a, b) => {
                if (a.label < b.label) {
                  return -1;
                }

                if (a.label > b.label) {
                  return 1;
                }

                return 0;
              });

              return items.map((item) => ({
                ...item,
              }));
            }}
          />
          <svg
            className="absolute top-0 right-0 pointer-events-none"
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            style={{ top: '16px', right: '9px' }}
          >
            <path
              fill="#909090"
              d="M4.51 7.54c0.43-0.44 1.04-0.48 1.57 0l3.90 3.74 3.90-3.74c0.53-0.48 1.14-0.44 1.57 0 0.43 0.44 0.40 1.19 0 1.61-0.40 0.41-4.69 4.50-4.69 4.50-0.21 0.22-0.50 0.33-0.78 0.33s-0.57-0.11-0.78-0.33c0 0-4.28-4.08-4.69-4.50s-0.43-1.17 0-1.61z"
            />
          </svg>
        </div>
        <div className={clsx(locale !== 'RU' && 'hidden', 'relative')}>
          <MenuSelect
            attribute="Specialty (RU)"
            translations={{
              seeAllOption: translations[locale].seeAll,
            }}
            limit={100}
            transformItems={(items: MenuSelectItem[]) => {
              items.sort((a, b) => {
                if (a.label < b.label) {
                  return -1;
                }

                if (a.label > b.label) {
                  return 1;
                }

                return 0;
              });

              return items.map((item) => ({
                ...item,
              }));
            }}
          />
          <svg
            className="absolute top-0 right-0 pointer-events-none"
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            style={{ top: '16px', right: '9px' }}
          >
            <path
              fill="#909090"
              d="M4.51 7.54c0.43-0.44 1.04-0.48 1.57 0l3.90 3.74 3.90-3.74c0.53-0.48 1.14-0.44 1.57 0 0.43 0.44 0.40 1.19 0 1.61-0.40 0.41-4.69 4.50-4.69 4.50-0.21 0.22-0.50 0.33-0.78 0.33s-0.57-0.11-0.78-0.33c0 0-4.28-4.08-4.69-4.50s-0.43-1.17 0-1.61z"
            />
          </svg>
        </div>
        <div className={clsx(locale !== 'UA' && 'hidden', 'relative')}>
          <MenuSelect
            attribute="Specialty (UA)"
            translations={{
              seeAllOption: translations[locale].seeAll,
            }}
            limit={100}
            transformItems={(items: MenuSelectItem[]) => {
              items.sort((a, b) => {
                if (a.label < b.label) {
                  return -1;
                }

                if (a.label > b.label) {
                  return 1;
                }

                return 0;
              });

              return items.map((item) => ({
                ...item,
              }));
            }}
          />
          <svg
            className="absolute top-0 right-0 pointer-events-none"
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            style={{ top: '16px', right: '9px' }}
          >
            <path
              fill="#909090"
              d="M4.51 7.54c0.43-0.44 1.04-0.48 1.57 0l3.90 3.74 3.90-3.74c0.53-0.48 1.14-0.44 1.57 0 0.43 0.44 0.40 1.19 0 1.61-0.40 0.41-4.69 4.50-4.69 4.50-0.21 0.22-0.50 0.33-0.78 0.33s-0.57-0.11-0.78-0.33c0 0-4.28-4.08-4.69-4.50s-0.43-1.17 0-1.61z"
            />
          </svg>
        </div>
      </Panel>
      <Panel
        header={
          <div className="uppercase text-xs my-3">
            {translations[locale].language}
          </div>
        }
      >
        <CustomToggleRefinement
          attribute="Language (DE)"
          label={translations[locale].german}
          value="TRUE"
        />
        <CustomToggleRefinement
          attribute="Language (EN)"
          label={translations[locale].english}
          value="TRUE"
        />
        <CustomToggleRefinement
          attribute="Language (UA)"
          label={translations[locale].ukrainian}
          value="TRUE"
        />
        <CustomToggleRefinement
          attribute="Language (RU)"
          label={translations[locale].russian}
          value="TRUE"
        />
      </Panel>
      <Panel
        header={
          <div className="uppercase text-xs my-3">
            {translations[locale].location}
          </div>
        }
      >
        <CustomToggleRefinement
          attribute="Treatment (Practice)"
          label={translations[locale].inPractice}
          value="TRUE"
        />
        <CustomToggleRefinement
          attribute="Treatment (Home)"
          label={translations[locale].homeCall}
          value="TRUE"
        />
      </Panel>
    </>
  );
};

export default Filters;
