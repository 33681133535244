import iconDefault from './iconDefaults';
import type { IconProps } from 'components/ui/Icon';

const Tooth = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="40"
    height="46"
    viewBox="0 0 40 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.39 5.7C39.59 8.1 39.69 10.5 39.69 11C39.69 11.8 39.69 14.6 36.39 20.6C33.79 26 33.79 26.5 33.79 31.4V33.4C33.79 41.4 30.49 45.6 27.29 45.6C23.79 45.6 21.99 41.6 21.99 33.8C21.99 31 21.29 30 20.89 29.7C20.69 29.5 20.39 29.4 20.19 29.4H19.19C18.99 29.4 18.79 29.5 18.49 29.7C18.09 30.1 17.39 31.1 17.39 33.8C17.39 41.6 15.59 45.6 12.09 45.6C10.09 45.6 8.29 44.1 7.09 41.5C6.79 40.7 7.09 39.8 7.89 39.4C8.69 39.1 9.59 39.4 9.99 40.2C10.79 41.8 11.59 42.5 12.19 42.5C12.99 42.5 14.39 40.6 14.39 33.8C14.39 30.7 15.09 28.5 16.69 27.3C17.89 26.3 19.19 26.2 19.89 26.3C20.59 26.3 21.89 26.4 23.09 27.3C24.59 28.5 25.39 30.7 25.39 33.8C25.39 40.7 26.89 42.5 27.59 42.5C28.69 42.5 30.89 39.7 30.89 33.4V31.4C30.79 26.2 30.89 25.1 33.79 19.2C36.59 13.5 36.39 11.2 36.39 11.2V11C36.39 11 36.39 8.9 35.39 6.9C34.19 4.4 31.89 3.1 28.79 3.1C26.69 3.1 24.49 3.8 21.79 5.2C21.59 5.3 21.39 5.4 21.09 5.4H18.79C18.59 5.4 18.29 5.3 18.09 5.2C15.39 3.8 13.19 3.1 11.09 3.1C7.99 3.1 5.79 4.4 4.49 6.9C3.49 8.9 3.49 11 3.49 11V11.3C3.49 11.3 3.39 13.5 6.09 19.2C8.99 25.1 8.99 26.1 8.99 31.4V33.4V34C9.09 34.9 8.39 35.6 7.49 35.6C6.69 35.7 5.89 35 5.89 34.1V31.4C5.89 26.6 5.89 26.1 3.29 20.6C0.39 14.6 0.29 11.8 0.39 11C0.39 10.5 0.49 8.1 1.69 5.7C2.89 3.1 5.59 0.09 11.19 0.09C13.69 0.09 16.29 0.89 19.29 2.4H20.79C23.79 0.79 26.39 0.09 28.89 0.09C34.49 0.09 37.09 3.1 38.39 5.7ZM24.49 10.1C22.79 10.8 21.19 11 19.69 11C17.34 11 15.48 10.36 14.89 10.16C14.78 10.12 14.71 10.1 14.69 10.1C13.89 9.7 13.49 8.8 13.79 8C14.09 7.2 14.99 6.8 15.79 7.1C15.80 7.1 15.85 7.11 15.93 7.13C16.63 7.36 19.96 8.45 23.29 7.1C24.19 6.8 25.09 7.2 25.39 8C25.69 8.9 25.29 9.8 24.49 10.1Z"
      fill={color}
    />
  </svg>
);

export default Tooth;
