import Link from 'components/ui/Link';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'hooks/useTranslation';
import styles from './QunosuiteBanner.module.scss';

const QunosuiteBanner = (): JSX.Element => {
  const { t, locale } = useTranslation(true);
  const { trackEvent } = useAnalytics();

  return (
    <div className={styles.banner}>
      <Link href={`https://www.qunosuite.com/${locale}`}>
        <a
          title={t('exploreQunosuite')}
          onClick={() =>
            trackEvent({
              category: 'CTA',
              action: 'CTA|Click',
              label: 'CTA|Home|TopBanner|Qunosuite',
            })
          }
        >
          <span className={styles.bold}>Qunosuite </span>
          {t('ourPatientManagementSoftware')}
        </a>
      </Link>
    </div>
  );
};

export default QunosuiteBanner;
