import clsx from 'clsx';
import { forwardRef, useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { CSSTransition } from 'react-transition-group';
import Button from 'components/Qds/Button';
import Icon from 'components/ui/Icon';
import { generateScopedTransitionClasses } from 'utils/transitions';
import { useTranslation } from 'hooks/useTranslation';
import styles from './SlideOut.module.scss';
import type { ReactNode } from 'react';

type Props = {
  activeSlide?: number;
  backdrop?: boolean;
  belowHeader?: boolean;
  children: ReactNode;
  currentProgress?: number;
  footer?: string | ReactNode;
  isMultiStep?: boolean;
  lazyLoad?: boolean;
  onClose?: () => void;
  onStepBack?: () => void;
  open: boolean;
  placement?: 'bottom' | 'left' | 'right' | 'top';
  roundedCorners?: boolean;
  showCloseIcon?: boolean;
  showCloseButton?: boolean;
  showPrevIcon?: boolean;
  showProgressBar?: boolean;
  title?: string;
  visuallyHidden?: boolean;
  className?: string;
  closeButtonText?: string;
};

const SlideOut = forwardRef<HTMLElement, Props>(
  (
    {
      backdrop = true,
      children,
      isMultiStep = false,
      onClose,
      onStepBack,
      open = false,
      placement = 'left',
      showPrevIcon = false,
      showCloseIcon = true,
      showCloseButton = false,
      visuallyHidden = false,
      lazyLoad = true,
      belowHeader = false,
      title,
      footer,
      activeSlide,
      showProgressBar = false,
      currentProgress = 0,
      roundedCorners = false,
      className,
      closeButtonText,
      ...props
    },
    ref,
  ): JSX.Element => {
    const [isOpen, setIsOpen] = useState(open);
    const [showContent, setShowContent] = useState(false);
    const [progress] = useState(currentProgress);
    const { t } = useTranslation();
    const closeText = closeButtonText ?? t('close');

    useEffect(() => {
      setIsOpen(open);
      if (true === open) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    }, [open]);

    const handleClose = (): void => {
      setIsOpen(false);

      document.body.classList.remove('overflow-hidden');
      onClose && onClose();
    };

    const handleArrowClick = (): void => {
      // eslint-disable-next-line
      // @ts-ignore
      if (isMultiStep && activeSlide > 0) {
        onStepBack?.();
      } else {
        setIsOpen(false);
        if (document.querySelectorAll('.SlideOutContent.isOpen').length === 1) {
          document.body.classList.remove('overflow-hidden');
          document.body.classList.remove('fixed');
          document.documentElement.classList.remove('overflow-hidden');
        }

        onClose?.();
      }
    };

    const overlayTransitionClasses = generateScopedTransitionClasses(
      'fade',
      styles,
    );

    return (
      <CSSTransition
        in={visuallyHidden ? true : isOpen}
        classNames={overlayTransitionClasses}
        mountOnEnter
        unmountOnExit
        onExiting={() => setShowContent(false)}
        onEntered={() => setShowContent(true)}
        timeout={{
          exit: 500,
        }}
      >
        <>
          {backdrop && (
            <div
              className={clsx(
                'fixed w-full h-screen top-0 bg-black opacity-75 left-0',
                belowHeader ? 'z-20' : ['z-50', styles.SlideOutBackdrop],
              )}
              onClick={handleClose}
            />
          )}
          <div
            {...props}
            className={clsx(
              'bg-white w-full md:w-2/5',
              styles.SlideOutContent,
              placement === 'right' && styles.rightPlacement,
              placement === 'left' && styles.leftPlacement,
              placement === 'top' && styles.topPlacement,
              placement === 'bottom' && styles.bottomPlacement,
              (showContent || (visuallyHidden && isOpen)) && styles.isOpen,
              roundedCorners && 'rounded-t-xl',
              className,
            )}
            // eslint-disable-next-line
            // @ts-ignore
            ref={ref}
            style={{ zIndex: 51 }}
          >
            {isMultiStep && showProgressBar && (
              <div className="h-2 w-full bg-bluishGrey">
                <div
                  className="bg-secondary h-2 rounded-r transition-all duration-300 ease-in-out"
                  style={{ width: `${progress || currentProgress}%` }}
                />
              </div>
            )}
            {showPrevIcon && (
              <button
                className="left-0 absolute z-10 ml-2 mt-4 bg-transparent border-0 flex justify-center items-center"
                onClick={handleArrowClick}
                title="Back"
                style={{ width: '40px', height: '40px' }}
              >
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.66 2.11C8.15 1.59 8.10 0.78 7.55 0.32C7.00 -0.14 6.15 -0.09 5.67 0.42L0.33 6.15C-0.11 6.63 -0.11 7.36 0.33 7.84L5.67 13.57C6.15 14.09 7.00 14.14 7.55 13.67C8.10 13.21 8.15 12.40 7.66 11.88L3.11 7L7.66 2.11Z"
                    fill="#585A51"
                  />
                </svg>
              </button>
            )}
            {showCloseIcon && (
              <button
                className={clsx(styles.closeBtn, 'right-0 absolute z-10')}
                onClick={handleClose}
                title={t('close')}
              >
                <Icon type="Cross" className="w-5 opacity-75" />
              </button>
            )}
            {title && (
              <h2 className="pt-1 text-center font-bold font-adelle text-2xl text-navy tracking-normal mt-14">
                {title}
              </h2>
            )}
            {lazyLoad ? <LazyLoad once>{children}</LazyLoad> : children}
            {showCloseButton && !footer ? (
              <div className="py-8 px-6">
                <Button onClick={handleClose} variant="secondary" fullWidth>
                  {closeText}
                </Button>
              </div>
            ) : null}
            {footer && <div className="py-8 px-6">{footer}</div>}
          </div>
        </>
      </CSSTransition>
    );
  },
);

SlideOut.displayName = 'SlideOut';
export default SlideOut;
